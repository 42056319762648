import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Form, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ServService } from '../../../app/serv.service';
import { Family } from '../../../Models/Family';
import { ChildrenRoport } from '../../../Models/ChildrenReports';
import * as moment from "moment"
import "ag-grid-enterprise";
import { LOCALE_TEXT } from '../../../app/Additions/constants';
import { CodeTableByBranch } from '../../../Models/CodeTableByBranch';
import { CodeTable } from 'src/Models/CodeTable';
import { FieldsToChange } from 'src/Models/FieldsToChange';


@Component({
  selector: 'update-class-children',
  templateUrl: './update-class-children.html',
  styleUrls: ['./update-class-children.css']
})
export class UpdateClassChildrenComponent implements OnInit {
  lTuitionRate: CodeTable;
  lClasses: Array<CodeTableByBranch>;
  lTransportRouteForth: Array<CodeTable>;
  lTransportRouteBack: Array<CodeTable>;
  lTransportRouteFriday: Array<CodeTable>;
  backgroundColor: string;
  private column;
  private rowData;
  private gridOptions;
  private gridColumnApi: any;
  private gridApi: any;
  private columnTypes;
  private visible: boolean;
  private childrenReport: Array<ChildrenRoport>
  currentiFamilyIdTemp;
  iBranchId: number;
  iClassId: number;
  iYearId: number;
  nvInstitutionSymbol: string;
  dEndDate:Date;
  iTranspGoType: number;
  iTranspReturnType: number;
  iTranspReturnFriType: number;
  fieldsToChange: FieldsToChange;
  iTuitionRateId: number ;
  iTranspRateId: number ;
  iMealsRateId: number ;

  ngOnDestroy() {
    this.serv.currentiFamilyId = this.currentiFamilyIdTemp

  }

  constructor(public serv: ServService, private route: Router) {
    this.backgroundColor = this.serv.backgroundColor[3];
    this.lTuitionRate = new CodeTable();
    this.lClasses = new Array<CodeTableByBranch>();
    this.lTransportRouteForth = new Array<CodeTable>();
    this.lTransportRouteBack = new Array<CodeTable>();
    this.lTransportRouteFriday = new Array<CodeTable>();
  }


  selectedRows
  ngOnInit() {
  this.GetTuitionRateCodeTable();
    this.serv.visible = true;
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      // domLayout: "forPrint",
      onGridReady: this.onGridReady,
      suppressRowClickSelection: true,
      defaultColDef: {
        resizable: true
    }

    },
      this.fieldsToChange = new FieldsToChange();
    this.column = [
      {
        headerName: "בחר",
        field: "checkbox",
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        suppressFilter: true,
        menuTabs: [],
        headerCheckboxSelection: true,
        width: 125,
      },
      {
        headerName: "משפחה ",
        field: "nvFamilyName",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " שם תלמיד",
        field: "nvFirstName",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כיתה",
        field: "nvLevel",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
        //   editable: true,
        //   cellEditor: 'agSelectCellEditor',
        //   cellEditorParams: {
        //     values: this.lClasses

        // }
      },
      {
        headerName: "סמל מוסד",
        field: "nvInstitutionSymbol",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שכונה",
        field: "nvNeighborhood",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תעריף שכ'ל",
        field: "nvTuitionRateName",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תעריף הסעה",
        field: "nvTranspRateName",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תעריף נוסף",
        field: "nvMealsRateName",
        width: 230,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תאריך יציאה",
        field: "dEndDate",
        width: 230,
        filter: "agTextColumnFilter",
        type: "dateColumn",
        sortable: true
      },
    ]

  };


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    //  params.api.sizeColumnsToFit();
  }
  GetUpdateClassChildren() {
    return this.serv.GetUpdateClassChildren(this.iBranchId, this.iYearId)
      .then(res => {

        for (let i = 0; i < res.length; i++) {
          res[i].isChecked = false;

          if (res[i].dEndDate != null)
                res[i].dEndDate = moment(res[i].dEndDate).format(
                  "DD/MM/YYYY"
                );
              else {
                res[i].dEndDate = "";
              }
        }
        this.rowData = res;
        this.selectedRows = this.rowData.length;
        this.lChildYears2=[]; // מאפס את רשימת הנבחרים שנשמרו בעבר
      })
      .catch(err => { console.log("err-----", err); return false; })
  }

  GetCodeTablesByBranch2() {
    return this.serv.GetCodeTablesByBranch()
      .then(res => {

        this.lClasses = []
        //get classes by branch
        for (let i = 0; i < res.length; i++) {
          if (res[i].nvTableName == "Classes") {
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (res[i].lCodeTable[j].iBranchId == this.iBranchId) {
                this.lClasses.push({ iCode: res[i].lCodeTable[j].iCode, id: res[i].lCodeTable[j].id, text: res[i].lCodeTable[j].text })
              }
            }
          }
        }
      })
      .catch(err => { console.log("err-----", err); return false; })
  }

  GetCodeTablesByBranch() {
    return this.serv
      .GetCodeTablesByBranch()
      .then(res => {
        this.lClasses = [];
        //get classes by branch
        for (let i = 0; i < res.length; i++) {
          if (res[i].nvTableName == "Classes") {
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (res[i].lCodeTable[j].iBranchId == this.iBranchId) {
                this.lClasses.push({ iCode: res[i].lCodeTable[j].iCode, id: res[i].lCodeTable[j].id, text: res[i].lCodeTable[j].text })
              }
            }
          }
          //סעה הלוך
          else if (res[i].nvTableName == "TransportRouteForth") {
            this.lTransportRouteForth = [];
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (
                res[i].lCodeTable[j].iBranchId == this.iBranchId
              ) {
                this.lTransportRouteForth.push({
                  length: null,
                  id: res[i].lCodeTable[j].id,
                  text: res[i].lCodeTable[j].text
                });
              }
            }
          }
          //הסעה חזור
          else if (res[i].nvTableName == "TransportRouteBack") {
            this.lTransportRouteBack = [];
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (
                res[i].lCodeTable[j].iBranchId == this.iBranchId
              ) {
                this.lTransportRouteBack.push({
                  length: null,
                  id: res[i].lCodeTable[j].id,
                  text: res[i].lCodeTable[j].text
                });
              }
            }
          }
          //הסעה שישי
          else if (res[i].nvTableName == "TransportRouteFriday") {
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (
                res[i].lCodeTable[j].iBranchId == this.iBranchId
              ) {
                this.lTransportRouteFriday.push({
                  length: null,
                  id: res[i].lCodeTable[j].id,
                  text: res[i].lCodeTable[j].text
                });
              }
            }
          }
        }
      })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }

  onBtExport() {
    // var params = {
    //   skipHeader: false,
    //   allColumns: false,
    //   fileName: 'פרטי תלמידים',
    //   sheetName: 'xls'
    // };
    // this.gridOptions.api.exportDataAsCsv(params);

    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: 'פרטי תלמידים'
    };
    this.gridApi.exportDataAsExcel(params);
  }
  
  onSelectionChanged(params) {
    this.lChildYears2 = params.api.getSelectedRows();
   
    // params.api.selectionController.lastSelectedNode.data.isChecked=!params.api.selectionController.lastSelectedNode.data.isChecked
  }
  onFilterChanged(params) {
    this.selectedRows = this.gridOptions.api.getModel().getRowCount();
  }

  lChildYears2 = [];
  // UpdClassChildren() {
  //   let lChildYears = [];
  //   //   this.rowData.forEach(element => {
  //   //     if(element.isChecked==true){
  //   //       lChildYears.push( element.iChildYearId );
  //   //     }
  //   //   });
  //   lChildYears = this.lChildYears2.map(x => x.iChildYearId);
  //   return this.serv.UpdClassChildren(this.iBranchId, this.iClassId, lChildYears)
  //     .then(res => {
  //       if (res == true)
  //         this.GetUpdateClassChildren();
  //     })
  //     .catch(err => { console.log("err-----", err); return false; })
  // }

  // UpdInstitutionSymbolChildren() {
  //   let lChildYears = [];
  //   // this.rowData.forEach(element => {
  //   //   if (element.isChecked == true) {
  //   //     lChildYears.push(element.iChildYearId);
  //   //   }
  //   // });
  //   lChildYears = this.lChildYears2.map(x => x.iChildYearId);
  //   return this.serv.UpdInstitutionSymbolChildren(lChildYears, this.nvInstitutionSymbol)
  //     .then(res => {
  //       if (res == true)
  //         this.GetUpdateClassChildren();
  //     })
  //     .catch(err => { console.log("err-----", err); return false; })
  // }

  UpdClassChildren() {
    let lChildYears = [];
    lChildYears = this.lChildYears2.map(x => x.iChildYearId);
    this.nvInstitutionSymbol ? this.fieldsToChange.nvInstitutionSymbol = this.nvInstitutionSymbol : null;
    this.iClassId ? this.fieldsToChange.iClassId = this.iClassId : null;
    this.iBranchId ? this.fieldsToChange.iBranchId = this.iBranchId : null;
    this.iTranspGoType ? this.fieldsToChange.iTranspGoType = this.iTranspGoType : null;
    this.iTranspReturnType ? this.fieldsToChange.iTranspReturnType = this.iTranspReturnType : null;
    this.iTranspReturnFriType ? this.fieldsToChange.iTranspReturnFriType = this.iTranspReturnFriType : null;
    this.iTranspGoType = this.iTranspReturnType = this.iTranspReturnFriType = this.iClassId = this.nvInstitutionSymbol = null;

    this.iTuitionRateId ? this.fieldsToChange.iTuitionRateId = this.iTuitionRateId : null;
    this.iTranspRateId ? this.fieldsToChange.iTranspRateId = this.iTranspRateId : null;
    this.iMealsRateId ? this.fieldsToChange.iMealsRateId = this.iMealsRateId : null;
    this.dEndDate ? this.fieldsToChange.dEndDate = new Date(this.dEndDate) : null;

    this.dEndDate=this.iMealsRateId=this.iTranspRateId=this.iTuitionRateId=null;

    return this.serv.UpdClassChildren(this.iBranchId, this.iClassId, lChildYears, this.fieldsToChange)
      .then(res => {
        if (res == true)
          this.GetUpdateClassChildren();
          this.lChildYears2=[];
      })
      .catch(err => { console.log("err-----", err); return false; })
  }

  GetTuitionRateCodeTable() {
    return this.serv
      .GetTuitionRateCodeTable()
      .then(res => { })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }

}
