import { Component, OnInit } from "@angular/core";
import { ParentDetails } from "../../Models/ParentDetails";
import { WindowRef } from "../../app/serv.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LOCALE_TEXT } from "../../app/Additions/constants";
import { AppProxy } from "../../app/app.proxy";
import * as moment from "moment";
import { DebitDetailsToMonthComponent } from "../debit-details-to-month/debit-details-to-month.component";
import { MatDialog } from "@angular/material/dialog";
import "ag-grid-enterprise";
import { ServService } from "../../app/serv.service";
import html2canvas from 'html2canvas';
import { timeInterval } from "rxjs-compat/operator/timeInterval";
import { Observable } from "rxjs";
import { ReportsDet } from "src/Models/ReportsDet";

@Component({
  selector: "parentDetails",
  templateUrl: "./parentDetails.component.html",
  styleUrls: ["./parentDetails.component.scss"]
})
export class parentDetailsComponent implements OnInit {
  public column;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;

  alertResponse = "";
  displayAlert = false;
  backgroundColor: string;
  disabled;
  Id: number = 3;
  Passport: number = 4;
  pattern = /^[a-zA-Zא-ת\s]*$/;
  pDetails: ParentDetails;
  form: FormGroup;
  isOneParentFull = false;
  isVisible = false;
  base64: string;
  nvImage: string;
  dFromDate: Date = undefined;

  constructor(
    private router: Router,
    public serv: ServService,
    private dMonthDailog: MatDialog,
    private winRef: WindowRef

  ) {
    this.pDetails = new ParentDetails();
    this.backgroundColor = this.serv.backgroundColor[0];
  }

  ngOnInit() {
    this.disabled = true;
    if (localStorage.getItem("dDebitFamilyFromDate") != null) {
      this.dFromDate = new Date(
        JSON.parse(localStorage.getItem("dDebitFamilyFromDate"))
      );
    }
    // this.dFromDate = this.serv.default1.dDebitFamilyFromDate;
    this.form = new FormGroup({
      nvFamilyName: new FormControl("", Validators.required),
      nvFirstNameF: new FormControl("", Validators.required),
      nvFirstNameM: new FormControl("", [
        Validators.required,
        Validators.minLength(1)
      ]),
      dBirthDateF: new FormControl(),
      dBirthDateM: new FormControl(),
      nvAddress: new FormControl("", [
        Validators.required,
        Validators.minLength(1)
      ]),
      nvIdentityF: new FormControl("", Validators.required),
      nvIdentityM: new FormControl("", Validators.required),
      nvPhoneF: new FormControl("", [
        Validators.required//,
        // Validators.maxLength(10),
        // Validators.minLength(10),
        // Validators.pattern("[0]+[5]+[0-9]*")
      ]),
      nvPhoneM: new FormControl("", [
        Validators.required//,
        // Validators.maxLength(10),
        // Validators.minLength(10),
        // Validators.pattern("[0]+[5]+[0-9]*")
      ]),
      nvLastNameF: new FormControl(),
      nvLastNameM: new FormControl(),
      // nvPassportF:new FormControl(""),
      iCityType: new FormControl("", Validators.required),
      nvWorkPlaceF: new FormControl(),
      nvWorkPlaceM: new FormControl(),
      nvPhone: new FormControl("", [Validators.required]),
      nvWorkAddressF: new FormControl(),
      nvWorkAddressM: new FormControl(),
      nvZipCode: new FormControl(),
      nvNeighborhood: new FormControl(),
      nvNumHouse: new FormControl(),
      // nvPassportM:new FormControl(),
      iIdentityTypeF: new FormControl("", Validators.required),
      iIdentityTypeM: new FormControl("", Validators.required),
      nvComments: new FormControl(),
      iChildrenCount: new FormControl(),
      nvCommunity: new FormControl(),
      nvEmail: new FormControl(),
    });
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      defaultColDef: {
        resizable: true
    },
      onGridReady: this.onGridReady,
    };

    if (this.serv.currentiFamilyId != -1) this.getParentDetails();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.closeToolPanel();
    params.api.sizeColumnsToFit();
  }

  getParentDetails() {
    console.log("currentiFamilyId  " + this.serv.currentiFamilyId);
    if (this.serv.currentiFamilyId == undefined)
      this.serv.currentiFamilyId = this.serv.currentFamily.iFamilyId;
    this.serv
      .GetParentDetails(this.serv.user.iUserId, this.serv.currentiFamilyId)
      .then(res => {
        this.pDetails = res;
        this.serv.currentFamily.nvFamilyName = this.pDetails.nvFamilyName;
        this.serv.currentFamily.nvMotherName = this.pDetails.nvFirstNameM;
        this.serv.currentFamily.nvFatherName = this.pDetails.nvFirstNameF;
      });
  }

  updateParentDetails() {
    if (this.form.valid == false) {
      // this.alertResponse = 'יש למלא את כל שדות החובה';
      // this.displayAlert = true;
      return;
    }

    this.serv
      .saveAndInsertParentDetails(
        this.serv.user.iUserId,
        this.serv.currentiFamilyId,
        this.pDetails
      )
      .then(res => {
        this.router.navigate(["/header/families/tabs/children-details"]);
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  onBlur(familyName) {
    if (
      this.serv.currentiFamilyId == -1 ||
      this.pDetails.nvLastNameF == this.pDetails.nvLastNameM
    ) {
      this.pDetails.nvLastNameF = familyName;
      this.pDetails.nvLastNameM = familyName;
    }
  }

  onClickRadioF(e) {
    console.log(e);
    if (e == 3) {
      //if it is ת.ז.
      this.form.controls["nvIdentityF"].clearValidators();
      this.form.controls["nvIdentityF"].setValidators([
        Validators.maxLength(9),
        Validators.minLength(9),
        this.serv.LegalTz
      ]);
      this.form.controls["nvIdentityF"].updateValueAndValidity();
    } else {
      this.form.controls["nvIdentityF"].clearValidators();
      this.form.controls["nvIdentityF"].updateValueAndValidity();
    }
  }

  onClickRadioM(e) {
    console.log(e);
    if (e == 3) {
      //if it is ת.ז.
      this.form.controls["nvIdentityM"].clearValidators();
      this.form.controls["nvIdentityM"].setValidators([
        Validators.maxLength(9),
        Validators.minLength(9),
        this.serv.LegalTz
      ]);
      this.form.controls["nvIdentityM"].updateValueAndValidity();
    } else {
      this.form.controls["nvIdentityM"].clearValidators();
    }
  }
  show(path) {
    let a = this.winRef.getNativeWindow();
    a.open(AppProxy.baseUrl + "Files/" + path);
  }

  ReportsDet: ReportsDet;
  lReportsDet: Array<ReportsDet> = new Array<ReportsDet>();
  printPdf() {
    let str = '<h1> דף חשבון להורה' + '<br>' + 'לכבוד' + ' '
    if (this.pDetails.nvFamilyName)
      str += this.pDetails.nvFamilyName + ' '
    if (this.pDetails.nvFirstNameF)
      str += this.pDetails.nvFirstNameF
    if (this.pDetails.nvFirstNameM)
      str += ' ו' + this.pDetails.nvFirstNameM
    str += '<br>'
    if (this.pDetails.nvAddress)
      str += this.pDetails.nvAddress + ' ';
    if (this.pDetails.nvNumHouse)
      str += this.pDetails.nvNumHouse + ' ';
    if (this.pDetails.nvNeighborhood)
      str += this.pDetails.nvNeighborhood + ' ';
    if (this.pDetails.nvCityName)
      str += this.pDetails.nvCityName;
    str += '<br>' + 'יתרה לתשלום:' + ' ' + this.pDetails.nBalance + '</h1>';
    this.serv.printToPDF1(this.gridOptions.columnApi, this.gridOptions, str, null);
  }

  basic() {
    var doc = require('jspdf');
    require('jspdf-autotable');

    // From HTML
    doc.autoTable({ html: '.MyGrid' });

    // From Javascript
    let finalY = doc.previousAutoTable.finalY;
    doc.text("From javascript arrays", 14, finalY + 15);
    doc.autoTable({
      startY: finalY + 20,
      head: [
        ['ID', 'Name', 'Email', 'Country', 'IP-address'],
      ],
      body: [
        ['1', 'Donna', 'dmoore0@furl.net', 'China', '211.56.242.221'],
        ['2', 'Janice', 'jhenry1@theatlantic.com', 'Ukraine', '38.36.7.199'],
        ['3', 'Ruth', 'rwells2@constantcontact.com', 'Trinidad and Tobago', '19.162.133.184'],
        ['4', 'Jason', 'jray3@psu.edu', 'Brazil', '10.68.11.42'],
        ['5', 'Jane', 'jstephens4@go.com', 'United States', '47.32.129.71'],
        ['6', 'Adam', 'anichols5@com.com', 'Canada', '18.186.38.37']
      ],
    });

    finalY = doc.previousAutoTable.finalY;
    doc.text("From HTML with CSS", 14, finalY + 15);
    doc.autoTable({
      startY: finalY + 20,
      html: '.table',
      useCss: true,
    });

    return doc;
  };

  onBtExport() {
    // var params = {
    //   skipHeader: false,
    //   allColumns: false,
    //   fileName: "דוח חשבון להורה",
    //   sheetName: "xls"
    // };
    // this.gridOptions.api.exportDataAsCsv(params);
    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "דוח חשבון להורה"
    };
    if (this.gridApi)
      this.gridApi.exportDataAsExcel(params);
    else {
      this.gridOptions.api.exportDataAsExcel(params);
    }
  }
  detailesAccount() {
    this.serv.GetAccountSheetForParent(this.dFromDate).then(res => {
      this.isVisible = true;
      for (let i = 0; i < res.length; i++) {
        if (res[i].dDebitDate != null) {
          let x = res[i].dDebitDate;
          res[i].dDebitDate = moment(x).format("DD/MM/YYYY");
          res[i].dDebitDate2 = moment(x).format("MM/DD/YYYY");
        } else if (res[i].dDebitDate == null) {
          res[i].dDebitDate = "";
        }
      }
      this.columnTypes = {
        dateColumn: {
          filter: "agDateColumnFilter",
          filterParams: {
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              if (cellValue != null) {
                let dateParts = cellValue.split("/");
                let cellDate = new Date(
                  Number(dateParts[1]) +
                  "/" +
                  Number(dateParts[0]) +
                  "/" +
                  Number(dateParts[2])
                );
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                } else if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          }
        }
      };
      this.column = [
        {
          headerName: "פירוט חודשי",
          width: 90,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,

          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="button" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.monthDetails(params.data);
            });
            return eDiv;
          }
        },
        {
          headerName: "חודש",
          field: "nvMonth",
          //width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "תאריך תשלום",
          field: "dDebitDate",
          //width: 150,
          filter: "agDateColumnFilter",
          type: "dateColumn",
          comparator: this.dateComparator
        },

        {
          headerName: "שם תלמיד",
          field: "nvFirstName",
          //width: 160,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום חיוב",
          field: "nAmountDebit",
          //width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום ששולם",
          field: "nAmountCredit",
          //width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "יתרה",
          field: "nBalance",
          //width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "מצטבר ",
          field: "nCumulativeBalance",
          //width: 150,
          filter: "agTextColumnFilter"
        }
      ];
      this.rowData = res;
    });
  }
  dateComparator(date1, date2) {
    if (date1 === null && date2 === null) {
      return 0;
    }
    if (date1 === null) {
      return -1;
    }
    if (date2 === null) {
      return 1;
    }
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    return cellDate1.getTime() - cellDate2.getTime();
  }
  GetDetailedAccountSheetForParent() {
    this.serv.GetDetailedAccountSheetForParent(this.dFromDate).then(res => {
      this.isVisible = true;
      for (let i = 0; i < res.length; i++) {
        if (res[i].dDebitDate != null) {
          let x = res[i].dDebitDate;
          res[i].dDebitDate = moment(x).format("DD/MM/YYYY");
          res[i].dDebitDate2 = moment(x).format("MM/DD/YYYY");
        } else if (res[i].dDebitDate == null) {
          res[i].dDebitDate = "";
        }
      }
      this.column = [
        {
          headerName: "פירוט חודשי",
          width: 120,
          suppressFilter: true,

          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML =
              '<button mat-icon-button type="button" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
            var eButton = eDiv.querySelectorAll("#edit")[0];
            eButton.addEventListener("click", function () {
              params.context.componentParent.monthDetails(params.data);
            });
            return eDiv;
          }
        },
        {
          headerName: "חודש",
          field: "nvMonth",
          width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "תאריך תשלום",
          field: "dDebitDate",
          width: 150,
          filter: "agTextColumnFilter"
        },

        {
          headerName: "שם תלמיד",
          field: "nvFirstName",
          width: 160,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "פרטים",
          field: "nvDetails",
          width: 150,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום חיוב",
          field: "nAmountDebit",
          width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום סכום ששולם",
          field: "nAmountCredit",
          width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "מצטבר ",
          field: "nCumulativeBalance",
          width: 150,
          filter: "agTextColumnFilter"
        }
      ];
      this.rowData = res;
    });
  }
  selectLogo(event: any, id) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event1: any) => {
        // if (id == 1) this.pDetails.identityF.nvFilePath = event1.target.result;
        // else this.pDetails.identityM.nvFilePath = event1.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);

      let x = this.fileChange(event, id);
      console.log(id, x, x.base64, x.nvImage);
      // if (id == 2) {
      //   console.log(2, x, x.base64, x.nvImage, this.pDetails);
      //   this.pDetails.identityF.nvFile = x.base64;
      //   this.pDetails.identityF.nvFileName = x.nvImage;
      // } else {
      //   console.log(1, x, x.base64, x.nvImage, this.pDetails);
      //   this.pDetails.identityM.nvFile = x.base64;
      //   this.pDetails.identityM.nvFileName = x.nvImage;
      // }
      // console.log(this.pDetails);
    }
  }
  fileChange(event, id) {
    let x = this;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var fileType = file.name.substring(
        file.name.indexOf(".") + 1,
        file.name.length
      );
      if ((window as any).FileReader) {
        var fileBase64 = "";
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        var fileName;
        fileReader.onload = function (e) {
          fileBase64 = (e.target as any).result.substring(
            (e.target as any).result.indexOf(",") + 1,
            (e.target as any).result.length
          );
          console.log(fileBase64);
          x.nvImage = file.name;
          x.base64 = fileBase64;
          if (id == 2) {
            console.log(2, x, x.base64, x.nvImage, x.pDetails);
            x.pDetails.identityF.nvFile = x.base64;
            x.pDetails.identityF.nvFileName = x.nvImage;
          } else {
            console.log(1, x, x.base64, x.nvImage, x.pDetails);
            x.pDetails.identityM.nvFile = x.base64;
            x.pDetails.identityM.nvFileName = x.nvImage;
          }

        };
      }
    }
    return x;
  }
  monthDetails(params) {
    // this.route.navigate(['/header/families/tabs/last-debits/details-to-month']);
    let detailsArr = [];

    detailsArr.push(params.dDebitDate2, params.nvMonth);
    this.dMonthDailog.open(DebitDetailsToMonthComponent, {
      hasBackdrop: true,
      height: "800px",
      width: "1400px",
      data: detailsArr
    });
  }
}
