export class FieldsToChange {
    iClassId: number ;
    iBranchId: number ;
    nvInstitutionSymbol: string;
    iTranspGoType: number ;
    iTranspReturnType: number ;
    iTranspReturnFriType: number ;
    iMealsRateId: number ;
    iTranspRateId: number ;
    iTuitionRateId: number ;
    dEndDate:Date;
}