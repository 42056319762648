export class paymentDetails{
iPaymentMethodId:number=-1
iBankType:number = undefined
ipriorityType:number= undefined
iPaymentMethodType:number= undefined
iStatusType :number= 18
iCVV:string = null
iBranchNumber :number= undefined
iaccountNumber :number= undefined
nMaxAmount :any= undefined
iAssociationId :number= undefined
dPausedToDate:Date= null
nvCard:string=null
nvExpirationDate:string=null
nFixedAmount:any= undefined
iRateType:number= undefined
bExternalCharge:Boolean
nvAccountIdentity:string=null
nvCardLastFourDigits:string=null
nvCardName :string=null
bExpirationDateUpdated: boolean = false
}
