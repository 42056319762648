import { Component, OnInit, ViewChild } from '@angular/core';
import { ServService } from '../../app/serv.service';
import { Setting } from '../../Models/setting';
import { Rate } from '../../Models/Rate';
import { LOCALE_TEXT } from '../../app/Additions/constants';

import { CellEditorFactory } from 'ag-grid';
import "ag-grid-enterprise";
import { GridApi } from "ag-grid/dist/lib/gridApi";
import { CodeTable } from '../../Models/CodeTable';
import { Year } from '../../Models/Year';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Classes } from '../../Models/Classes';
import { Association } from '../../Models/Association';
import { Cities } from '../../Models/Cities';
import { MenuItem } from 'primeng/api';
import { Branch } from '../../Models/Branch';
import { MomentModule } from 'angular2-moment';
import * as moment from "moment"
import { DebitDay } from '../../Models/DebitDay';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TransportRoute } from '../../Models/TransportRoute';
import { YearCodeTable } from '../../Models/YearCodeTable';
import { Default } from '../../Models/Default';
import { CodeTableByBranch } from '../../Models/CodeTableByBranch';
import { Discont } from '../../Models/Discont';
import { MatDialog } from '@angular/material';
import { dailog } from 'src/app/Additions/dailog/dailog';
// import { DatepickerModule } from 'angular-mat-datepicker'

@Component({
  selector: 'Setting',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingComponent implements OnInit {
  isVisible9: boolean;
  lClasses: Array<CodeTableByBranch> = new Array<CodeTableByBranch>();
  lReceiptType;
  bnTransportRoute: boolean = false;
  bniBranchId: boolean = false;
  bnvRateName: boolean = false;
  isVisible7: boolean;
  date = new FormControl(new Date());
  myDate: Date;
  isVisible6: boolean;
  isVisible5: boolean;
  isVisible4: boolean;
  isVisible3: boolean;
  isVisible2: boolean;
  isVisible1: boolean;
  settingAdittion: any;
  isVisible: boolean;
  backgroundColor: string;
  lRates: Array<Rate>;
  rateTypeId: number;
  isVisibleGrid = true;
  defaults: Default
  public YearCodeTable: YearCodeTable[]
  public columnDefs;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: GridApi;
  public columnTypes;
  public currentSysTable: string;
  public Discont: Discont = new Discont();
  public Year: Year = new Year();
  public Classes: Classes = new Classes();
  public Branch: Branch = new Branch();
  public Association: Association = new Association();
  public DebitDay: DebitDay = new DebitDay();
  public lAssociation: Association[]
  public Rate: Rate = new Rate();
  public TransportRoute: TransportRoute = new TransportRoute()
  form: FormGroup;
  invalidDiscont: boolean = false;
  disnvAssociationCode: boolean = false;
  disnvAssociationSend: boolean = false;
  disnvTerminalNum: boolean = false;
  disnvTerminalPass: boolean = false;
  disnvTerminalUser: boolean = false;

  constructor(public serv: ServService, private mydialog: MatDialog) {
    this.defaults = new Default();
    this.lRates = new Array<Rate>();
    this.rateTypeId = this.serv.lRateType["0"].iId;
    this.backgroundColor = this.serv.backgroundColor[4];
    this.DebitDay.iDebitDayId = -1;
    this.myDate = new Date();
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(Number(dateParts[1]) + "/" + Number(dateParts[0]) + "/" + Number(dateParts[2]));
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
  }

  ngOnInit() {
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      onGridReady: this.onGridReady,
      context: { componentParent: this },
      defaultColDef: {
        resizable: true
    },
      enableRtl: true
    }
    this.currentSysTable = "3";
    this.serv.GetAssociation().then(res => {
      this.lAssociation = res;
    }).catch(err => { console.log("err-----" + err); })
    this.serv.GetRates(this.rateTypeId).then(res => {
      for (let i = 0; i < res.length; i++) {
        if(res[i].dUntilDate!=null){
         res[i].dUntilDate = moment(res[i].dUntilDate).format("DD/MM/YYYY");
        console.log(res[i].dUntilDate);
        }
      }
    this.lRates = res; 
      
      //    this.rowData = this.lRates.filter(item => item.iRateId == 1)[0];
      this.rowData = this.lRates.filter(i => i.iRateTypeId == this.rateTypeId);
    }).catch(err => { console.log("err-----" + err); })

    this.columnDefs = [
      {
        headerName: "עריכה",
        field: "iRateId",
        width: 120,
        suppressFilter: true,
        cellRenderer: function (params) {
          var eDiv = document.createElement("div");
          eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>';
          var eButton = eDiv.querySelectorAll('#edit')[0];
          eButton.addEventListener('click', function () {
            params.context.componentParent.editSetting(params.data, 3);
          });


          return eDiv;
        }
      },
      {
        headerName: "סוג תעריף",
        field: "nvRateName",
        width: 300,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום לתשלום",
        field: "nRate",
        width: 200,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום לתשלום חלופי",
        field: "nSecondaryRate",
        width: 200,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תאריך סיום",
        field: "dUntilDate",
        // cellRenderer: (data) => {return moment(data.dUntilDate).format('DD/MM/YYYY')},
        width: 200,
        filter: "agDateColumnFilter",
        sortable: true
      },
      {
        headerName: "עמותה",
        field: "nvAssociationName",
        width: 300,
        filter: "agTextColumnFilter",
        sortable: true
      }

    ]

    this.lReceiptType = this.serv.lReceiptType;
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }
  ngDoCheck() {
    if (this.lClasses.length > 0) {
      if (this.Discont.iClassIdFrom > 0 && this.Discont.iClassIdTo > 0) {
        let iFromcode = this.lClasses.filter(i => i.id == this.Discont.iClassIdFrom);
        let iTocode = this.lClasses.filter(i => i.id == this.Discont.iClassIdTo);

        if (iFromcode.length > 0 && iTocode.length > 0 && iFromcode[0].iCode > iTocode[0].iCode) {
          this.invalidDiscont = true;

        }
        else {
          this.invalidDiscont = false;
        }
      }


    }

  }


  getFinancialEndDate(iYearId) {
    this.Year.dFinancialEndDate = this.YearCodeTable.filter(i => i.iYearId == iYearId)[0].dFinancialEndDate;
  }

  onChange() {
    if (this.currentSysTable != "8") {
      this.isVisibleGrid = true;
    }
    this.isVisible = false
    if (this.currentSysTable == "1") {
      this.serv.GetYears().then(res => {
        for (let i = 0; i < res.length; i++) {
          res[i].dBeginDate = moment(res[i].dBeginDate).format("DD/MM/YYYY");
          res[i].dEndDate = moment(res[i].dEndDate).format("DD/MM/YYYY");
          res[i].dFinancialEndDate = moment(res[i].dFinancialEndDate).format("DD/MM/YYYY");
        }
        this.rowData = res;
        this.serv.GetYearCodeTable().then(res => {
          this.YearCodeTable = res;
        }).catch(err => { console.log("err-----" + err); })
        console.log(res)
        this.columnDefs = [
          {
            headerName: "עריכה", field: "iYearId", width: 120, suppressFilter: true, cellRenderer: function (params) {
              var eDiv = document.createElement("div");
              eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>'; var eButton = eDiv.querySelectorAll('#edit')[0]; eButton.addEventListener('click', function () {
                params.context.componentParent.editSetting(params.data, 1);
              }); return eDiv;
            }
          },
          { headerName: "שנה עברית", field: "nvJewYear", width: 400, filter: "agTextColumnFilter", sortable: true  },
          { headerName: "ת.התחלה", field: "dBeginDate", width: 400, filter: "agDateColumnFilter", type: "dateColumn", sortable: true  },
          { headerName: "ת.סיום", field: "dEndDate", width: 400, filter: "agDateColumnFilter", type: "dateColumn", sortable: true  },
          { headerName: "סוף שנה פיננסית", field: "dFinancialEndDate", width: 400, filter: "agDateColumnFilter", type: "dateColumn", sortable: true  },
        ]
      }).catch(err => { console.log("err-----" + err); })

    }
    else if (this.currentSysTable == "2") {
      this.serv.GetClasses().then(res => {
        this.rowData = res;
        console.log(res)
        this.columnDefs = [
          {
            headerName: "עריכה", field: "iClassId", width: 120, suppressFilter: true, cellRenderer: function (params) {
              var eDiv = document.createElement("div");
              eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>'; var eButton = eDiv.querySelectorAll('#edit')[0]; eButton.addEventListener('click', function () {
                params.context.componentParent.editSetting(params.data, 2);
              }); return eDiv;
            }
          },

          { headerName: "מספר כתה", field: "iClassCode", width: 500, filter: "agTextColumnFilter", sortable: true },
          { headerName: "כתה", field: "nvLevel", width: 500, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "סניף", field: "nvBranchName", width: 500, filter: "agTextColumnFilter" , sortable: true }]
      }).catch(err => { console.log("err-----" + err); })
      // this.onGridReady(this.rowData);
    }
    else if (this.currentSysTable == "4") {
      this.serv.GetBranches().then(res => {
        this.rowData = res;
        this.columnDefs = [
          {
            headerName: "עריכה", field: "iBranchId", width: 120, suppressFilter: true, cellRenderer: function (params) {
              var eDiv = document.createElement("div");
              eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>'; var eButton = eDiv.querySelectorAll('#edit')[0]; eButton.addEventListener('click', function () {
                params.context.componentParent.editSetting(params.data, 4);
              }); return eDiv;
            }
          },
          { headerName: "שם", field: "nvBranchName", width: 700, filter: "agTextColumnFilter" , sortable: true },

          { headerName: "כתובת", field: "nvAddress", width: 700, filter: "agTextColumnFilter" , sortable: true }]
      }).catch(err => { console.log("err-----" + err); })
      // this.onGridReady(this.rowData);
    }
    else if (this.currentSysTable == "3") {
      this.serv.GetAssociation().then(res => {
        this.lAssociation = res;
      }).catch(err => { console.log("err-----" + err); })
      this.serv.GetRates(this.rateTypeId).then(res => {
        for (let i = 0; i < res.length; i++) {
          if(res[i].dUntilDate!=null){
          res[i].dUntilDate = moment(res[i].dUntilDate).format("DD/MM/YYYY");
          console.log(res[i].dUntilDate);
          }
        this.lRates = res;
        //    this.rowData = this.lRates.filter(item => item.iRateId == 1)[0];
        this.rowData = this.lRates.filter(i => i.iRateTypeId == 22);
        this.columnDefs = [{
          headerName: "עריכה", field: "iRateId", width: 120, suppressFilter: true, cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>'; var eButton = eDiv.querySelectorAll('#edit')[0]; eButton.addEventListener('click', function () {
              params.context.componentParent.editSetting(params.data, 3);
            }); return eDiv;
          }
        },
        { headerName: "שם תעריף", field: "nvRateName", width: 300, filter: "agTextColumnFilter" , sortable: true },
        { headerName: "סכום לתשלום", field: "nSecondaryRate", width: 300, filter: "agTextColumnFilter" , sortable: true },
        { headerName: "סכום לתשלום חלופי", field: "iAssociationId", width: 300, filter: "agTextColumnFilter" , sortable: true },
        { headerName: "תאריך סיום", field: "dUntilDate", width: 300, filter: "agDateColumnFilter", sortable: true },
        { headerName: "עמותה", field: "nvAssociationName", width: 300, filter: "agTextColumnFilter", sortable: true }]
      }}).catch(err => { console.log("err-----" + err); })
      // this.onGridReady(this.rowData);
    }
    else if (this.currentSysTable == "5") {
      
      this.serv.GetAssociation().then(res => {
        
        for (let i = 0; i < res.length; i++) {
          res[i].nvActiv = res[i].iActive == 0 ? 'לא' : 'כן';
        }
        this.rowData = res;

        this.columnDefs = [
          {
            // הפוך ללא פעיל
            headerName: "עריכה", field: "iAssociationId", width: 120, suppressFilter: true, cellRenderer: function (params) {
              var eDiv = document.createElement("div");
              eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>';
              var eButton = eDiv.querySelectorAll('#edit')[0]; eButton.addEventListener('click', function () {
                // params.context.componentParent.editActiv(params.data);
                params.context.componentParent.editSetting(params.data, 5);
              }); return eDiv;
            }
          },
          { headerName: "שם", field: "nvAssociationName", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "קוד מוסד (8 ספרות)", field: "nvAssociationCode", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "קוד מוסד שולח (5 ספרות)", field: "nvAssociationSend", width: 150, filter: "agTextColumnFilter", sortable: true  },
          { headerName: " אשראי", field: "nvTerminalNum", width: 200, filter: "agTextColumnFilter", sortable: true  },
          { headerName: "סיסמא ", field: "nvTerminalPass", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "משתמש אשראי ", field: "nvTerminalUser", width: 200, filter: "agTextColumnFilter", sortable: true  },
          { headerName: "פעיל ", field: "nvActiv", width: 150, filter: "agTextColumnFilter" , sortable: true }
        ]
      }).catch(err => { console.log("err-----" + err); })
      // this.onGridReady(this.rowData);
    }

    else if (this.currentSysTable == "6") {
      this.serv.GetDebitDays().then(res => {
        this.rowData = res;
        this.columnDefs = [
          {
            headerName: "עריכה", field: "iDebitDayId", width: 400, suppressFilter: true, cellRenderer: function (params) {
              var eDiv = document.createElement("div");
              eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>'; var eButton = eDiv.querySelectorAll('#edit')[0]; eButton.addEventListener('click', function () {
                params.context.componentParent.editSetting(params.data, 6);
              }); return eDiv;
            }
          },
          { headerName: "יום חיוב", field: "nvDebitDay", width: 1000, filter: "agTextColumnFilter", sortable: true  }]
      }).catch(err => { console.log("err-----" + err); })
      // this.onGridReady(this.rowData);
    }
    else if (this.currentSysTable == "7") {

      this.serv.GetTransportRoutes().then(res => {
        this.rowData = res;
        this.columnDefs = [
          {
            headerName: "עריכה", field: "iAssociationId", width: 120, suppressFilter: true, cellRenderer: function (params) {
              var eDiv = document.createElement("div");
              eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>'; var eButton = eDiv.querySelectorAll('#edit')[0]; eButton.addEventListener('click', function () {
                params.context.componentParent.editSetting(params.data, 5);
              }); return eDiv;
            }
          },
          { headerName: "סניף", field: "nvBranchName", width: 300, filter: "agTextColumnFilter", sortable: true  },
          { headerName: "שם ", field: "nvTransportRouteName", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "סוג", field: "nvTransportRouteType", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: " מלווה", field: "nvEscortName", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "טלפון מלווה ", field: "nvEscortPhone", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "הערות ", field: "nvComment", width: 300, filter: "agTextColumnFilter" , sortable: true }]
      }).catch(err => { console.log("err-----" + err); })
      // this.onGridReady(this.rowData);
    }
    else if (this.currentSysTable == "8") {
      this.isVisibleGrid = false;
      this.serv.GetDefault().then(res => {
        if (res) {
          this.defaults = res;
        }
      }).catch(err => { console.log("err-----" + err); })
    }
    else if (this.currentSysTable == "9") {

      this.serv.GetDiscounts().then(res => {
        this.rowData = res;
        this.columnDefs = [
          {
            headerName: "עריכה", field: "iAssociationId", width: 120, suppressFilter: true, cellRenderer: function (params) {
              var eDiv = document.createElement("div");
              eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>'; var eButton = eDiv.querySelectorAll('#edit')[0]; eButton.addEventListener('click', function () {
                params.context.componentParent.editSetting(params.data, 9);
              }); return eDiv;
            }
          },
          { headerName: "סניף", field: "nvBranchName", width: 300, filter: "agTextColumnFilter", sortable: true  },
          { headerName: "מכיתה ", field: "nvLevelFrom", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: " לכיתה", field: "nvLevelTo", width: 200, filter: "agTextColumnFilter", sortable: true  },
          { headerName: " מס תלמיד", field: "iChildNumber", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "הנחה אחוז", field: "nDiscPerc", width: 200, filter: "agTextColumnFilter" , sortable: true },
          { headerName: "הנחה סכום ", field: "nDiscAmount", width: 300, filter: "agTextColumnFilter" , sortable: true }]
      }).catch(err => { console.log("err-----" + err); })
      // this.onGridReady(this.rowData);

    }
  }
  onSelectRate() {
    this.Rate = new Rate();
    this.rowData = this.lRates.filter(i => i.iRateTypeId == this.rateTypeId);
    this.isVisible3 = false
  }
  editActiv(params) {
    this.Association = params;
    this.Association.iActive = this.Association.iActive == 0 ? 1 : 0;
    this.Association.nvActiv = this.Association.nvActiv == 'לא' ? 'כן' : 'לא';
    this.serv.InsUpdAssociations(this.Association).then(res => {


      this.rowData.forEach(element => {
        if (element.iAssociationId == res) {
          element = this.Association
          this.gridApi.setRowData(this.rowData);
        }
      });

    }).catch(err => { console.log("err-----" + err); })
  }
  editSetting(params, id) {
    this.serv.GetCodeTable().then(res => {
      if (this.currentSysTable == "1") {

        this.Year = params;

        this.isVisible1 = false
        this.isVisible1 = true
        this.getFinancialEndDate(this.Year.iYearId)
      }

      if (this.currentSysTable == "2") {
        this.Classes = params;
        this.isVisible2 = true
      }

      if (this.currentSysTable == "3") {
        this.Rate = params;
        this.isVisible3 = true
      }
      if (this.currentSysTable == "4") {
        this.Branch = params;
        return this.serv.GetCodeTablesByBranch()
          .then(res => {
            console.log(res)

            this.lClasses = []
            //get classes by branch
            for (let i = 0; i < res.length; i++) {
              if (res[i].nvTableName == "Classes") {
                for (let j = 0; j < res[i].lCodeTable.length; j++) {
                  if (res[i].lCodeTable[j].iBranchId == this.Branch.iBranchId) {
                    this.lClasses.push({ iCode: res[i].lCodeTable[j].iCode, id: res[i].lCodeTable[j].id, text: res[i].lCodeTable[j].text })


                  }
                }
              }
            }
            this.isVisible4 = true
          })
          .catch(err => { console.log("err-----" + err); return false; })
      }
      if (this.currentSysTable == "5") {
        this.disnvAssociationCode = this.disnvAssociationSend = this.disnvTerminalNum = this.disnvTerminalPass = this.disnvTerminalUser = false;
        this.Association = params;
        this.isVisible5 = true;
        params.nvAssociationCode ? this.disnvAssociationCode = true : false;
        params.nvAssociationSend ? this.disnvAssociationSend = true : false;
        params.nvTerminalNum ? this.disnvTerminalNum = true : false;
        params.nvTerminalPass ? this.disnvTerminalPass = true : false;
        params.nvTerminalUser ? this.disnvTerminalUser = true : false;

        // if (this.serv.user.bRivchit)
        //   this.Association.iReceiptType = 77;
      }

      if (this.currentSysTable == "6") {
        this.DebitDay = params;
        this.isVisible6 = true
      }
      if (this.currentSysTable == "7") {
        this.TransportRoute = params;
        this.isVisible7 = true
      }
      if (this.currentSysTable == "9") {
        this.Discont = params;
        this.isVisible9 = true
      }
    })
      .catch(err => { console.log("err-----" + err); return false; })
  }

  addSetting() {
    this.serv.GetCodeTable().then(res => {
      if (this.currentSysTable == "1") {
        this.isVisible1 = false
        this.isVisible1 = true
        this.Year = new Year();
        this.Year.iYearId = this.serv.iYearId + 1;
        this.getFinancialEndDate(this.Year.iYearId)
      }

      if (this.currentSysTable == "2") {
        this.isVisible2 = true
        this.Classes = new Classes()
      }

      if (this.currentSysTable == "3") {

        this.isVisible3 = false
        if (this.isVisible3 == false)
          this.isVisible3 = true
        this.Rate = new Rate();

      }

      if (this.currentSysTable == "4") {
        this.isVisible4 = true
        this.lClasses = []
        this.Branch = new Branch()
        this.Branch.bIsChildInLastClassBecomesGraduate = true;
        this.Branch.bIsGoUpClasses = true;
      }

      if (this.currentSysTable == "5") {
        this.isVisible5 = true
        this.Association = new Association();
        if (this.serv.user.iReceiptCompany == 1078 || this.serv.user.iReceiptCompany == 1079)
          this.Association.iReceiptType = 77;
      }

      if (this.currentSysTable == "6") {
        this.isVisible6 = true
        this.DebitDay = new DebitDay()
      }
      if (this.currentSysTable == "7") {
        this.isVisible7 = true
        this.TransportRoute = new TransportRoute()
      }
      if (this.currentSysTable == "9") {
        this.isVisible9 = true
        this.Discont = new Discont()
      }
    })
      .catch(err => { console.log("err-----" + err); return false; })
  }

  saveSetting() {
    if (this.currentSysTable == "1") {

      this.isVisible1 = false
      this.serv.InsUpdYears(this.Year).then(res => {
        if (res > 0) {
          if (this.Year.nvJewYear == undefined) {
            this.serv.GetYears().then(res => {
              for (let i = 0; i < res.length; i++) {
                res[i].dBeginDate = moment(res[i].dBeginDate).format("DD/MM/YYYY");
                res[i].dEndDate = moment(res[i].dEndDate).format("DD/MM/YYYY");
                if (res[i].dFinancialEndDate != null)
                  res[i].dFinancialEndDate = moment(res[i].dFinancialEndDate).format("DD/MM/YYYY");
              }
            }).catch(err => { console.log("err-----" + err); })
            this.Year = new Year()
          }
          else {
            this.rowData.forEach(element => {
              if (element.iYearId == res) {
                element = this.Year
                element.dBeginDate = moment(this.Year.dBeginDate).format("DD/MM/YYYY");
                element.dEndDate = moment(this.Year.dEndDate).format("DD/MM/YYYY");
                element.dFinancialEndDate = moment(this.Year.dFinancialEndDate).format("DD/MM/YYYY");
                this.gridApi.setRowData(this.rowData);
              }
            });
          }
        }
      }).catch(err => { console.log("err-----" + err); })
    }
    if (this.currentSysTable == "2") {
      if (this.Classes.iBranchId == -1) {
        this.bniBranchId = true;
        return;
      }

      else {
        this.isVisible2 = false
        this.serv.InsUpdClasses(this.Classes).then(res => {
          this.serv.GetClasses().then(res => {
            this.rowData = res;
            this.rowData.forEach(element => {
              if (element.iClassId == res) {
                element = this.Classes
                this.gridApi.setRowData(this.rowData);
              }
            });
          }).catch(err => { console.log("err-----" + err); })
          // this.onGridReady(this.rowData)
        }).catch(err => { console.log("err-----" + err); })
      }
    }
    if (this.currentSysTable == "3") {
      if (this.Rate.nvRateName == undefined) {
        this.bnvRateName = true;
        return;
      }
      else {
        this.isVisible3 = false
        if (this.Rate.iRateId == -1)
          this.Rate.iRateTypeId = this.rateTypeId;
        this.serv.InsUpdRates(this.Rate).then(res => {

          if (this.Rate.iRateId == -1) {
            this.Rate.iRateId = res
            this.rowData.push(this.Rate);
            this.gridApi.setRowData(this.rowData);
            this.Rate = new Rate();
          }
          else {
            this.rowData.forEach(element => {
              if (element.iRateId == res) {
                element = this.Rate
                if(element.dUntilDate!=null){
                  element.dUntilDate = moment(element.dUntilDate).format("YYYY/MM/DD");
                }
                this.gridApi.setRowData(this.rowData);
              }
            });
          }
        }).catch(err => { console.log("err-----" + err); })
      }
    }
    if (this.currentSysTable == "4") {
      this.isVisible4 = false
      this.serv.InsUpdBranches(this.Branch).then(res => {

        if (res > 0) {
          if (this.Branch.iBranchId == -1) {
            this.Branch.iBranchId = res
            this.rowData.push(this.Branch);
            this.gridApi.setRowData(this.rowData);
            this.Branch = new Branch();
          }
          else {

            this.rowData.forEach(element => {
              if (element.iBranchId == res) {
                element = this.Branch
                this.gridApi.setRowData(this.rowData);
              }
            });
          }
        }
      }).catch(err => { console.log("err-----" + err); })
    }
    if (this.currentSysTable == "5") {
      this.isVisible5 = false
      this.Association.iActive = this.Association.iActive ? 1 : 0;
      this.serv.InsUpdAssociations(this.Association).then(res => {

        
        this.serv.GetAssociation().then(res => {
          for (let i = 0; i < res.length; i++) {
            res[i].nvActiv = res[i].iActive == 0 ? 'לא' : 'כן';

          }
          this.rowData = res;
        });

        if (this.Association.iAssociationId == -1) {
          this.Association.iAssociationId = res;
          this.rowData.push(this.Association);
          this.gridApi.setRowData(this.rowData);
          this.Association = new Association();
        }
        else {
          this.rowData.forEach(element => {
            element.iActive == false ? 0 : 1;
            element.nvActiv = element.iActive == 0 ? 'לא' : 'כן';

            this.gridApi.setRowData(element);

            if (element.iAssociationId == res) {
              element = this.Association
              this.gridApi.setRowData(this.rowData);
            }
          });
          this.rowData = res;
        }
      }).catch(err => { console.log("err-----" + err); })
    }
    if (this.currentSysTable == "6") {
      this.isVisible6 = false
      this.serv.InsUpdDebitDay(this.DebitDay).then(res => {
        if (this.DebitDay.iDebitDayId == -1) {
          this.DebitDay.iDebitDayId = res
          this.rowData.push(this.DebitDay);
          this.gridApi.setRowData(this.rowData);
          this.DebitDay = new DebitDay();
          this.DebitDay.iDebitDayId = -1;
        }
        else {
          this.rowData.forEach(element => {
            if (element.iDebitDayId == res) {
              element = this.DebitDay
              this.gridApi.setRowData(this.rowData);
            }
          });
        }
      }).catch(err => { console.log("err-----" + err); })
    }
    if (this.currentSysTable == "7") {
      if (this.TransportRoute.iBranchId == -1 || this.TransportRoute.iTransportRouteType == -1 || this.TransportRoute.nvTransportRouteName == undefined) {
        this.bnTransportRoute = true
        return;
      }
      else {
        this.isVisible7 = false
        this.serv.InsUpdTransportRoute(this.TransportRoute).then(res => {
          if (res > 0) {
            this.serv.GetTransportRoutes().then(res => {
              this.rowData = res;
            }).catch(err => { console.log("err-----" + err); })
          }

        }).catch(err => { console.log("err-----" + err); })
      }

    }
    if (this.currentSysTable == "9") {

      if (this.invalidDiscont == true)
        return

      this.isVisible9 = false
      this.serv.InsUpdDiscounts(this.Discont).then(res => {
        if (res > 0) {
          this.serv.GetDiscounts().then(res => {
            this.rowData = res;
          }).catch(err => { console.log("err-----" + err); })
        }

      }).catch(err => { console.log("err-----" + err); })


    }
  }
  checkNotNumber(event) {
    let bool: boolean = event.target.valid;
    let res: string;
    let reg: RegExp = new RegExp("^([^0-9]*)$");
    for (let i = 0; i < event.target.value.length; i++) {
      if (reg.test(event.target.value.charAt(i))) {
        res =
          event.target.value.substring(0, i) +
          event.target.value.substring(i + 1, event.target.value.length);
        event.target.value = res;
        if (event.target.value === "") event.target.value = null;
        else event.target.valid = bool;
      }
    }
  }
  ClassesUpgrading() {
    this.serv.ClassesUpgrading(this.Year.iYearId).then(res => {
      this.isVisible1 = false;
      this.serv.GetYears().then(res => {
        for (let i = 0; i < res.length; i++) {
          res[i].dBeginDate = moment(res[i].dBeginDate).format("YYYY/MM/DD");
          res[i].dEndDate = moment(res[i].dEndDate).format("YYYY/MM/DD");
          if (res[i].dFinancialEndDate != null)
            res[i].dFinancialEndDate = moment(res[i].dFinancialEndDate).format("YYYY/MM/DD");
        }
      }).catch(err => { console.log("err-----" + err); })
      this.Year = new Year()
    })
  }
  GetCodeTablesByBranch(iBranchId) {
    return this.serv.GetCodeTablesByBranch()
      .then(res => {
        console.log(res)

        this.lClasses = []
        //get classes by branch
        for (let i = 0; i < res.length; i++) {
          if (res[i].nvTableName == "Classes") {
            for (let j = 0; j < res[i].lCodeTable.length; j++) {
              if (res[i].lCodeTable[j].iBranchId == iBranchId) {
                this.lClasses.push({ iCode: res[i].lCodeTable[j].iCode, id: res[i].lCodeTable[j].id, text: res[i].lCodeTable[j].text })


              }
            }
          }
        }
      })
      .catch(err => { console.log("err-----" + err); return false; })
  }
  saveDefault() {

    this.serv.UpdDefault(this.defaults).then(res => {
      // if (res) {

      // }
    })

  }

  addToken() {
    if ((this.serv.user.iReceiptCompany == 1078 || this.serv.user.iReceiptCompany == 1079) 
    && this.Association.nvRivchitToken != "" && this.Association.nvRivchitToken != null) {
      const dialogRef = this.mydialog.open(dailog, {
        hasBackdrop: true,
        height: "200px",
        width: "300px",
        data: "יש לזכור להוסיף בריוחית סוג תשלום הו'ק, קוד 340"
      });
    }
  }



}
