export class FamilyCredit {
  iCreditId: number;
  iCheckId: number;
  iFamilyId: number;
  iCreditMethodType: number;
  dCreditDate: Date = new Date();
  dTransactDate: Date = new Date();
  nAmount: number;
  iPaymentNum: number;
  iPaymentMethodId: number;
  iCheckNum: number;
  dCheckDate: Date;
  iAssociationId: number;
  nvCardLastFourDigits: string;
  nvCardNumber: string;
  nvTokenNumber: string;
  nvExpirationDate: string;
  iCVV: number;
  iStatusType: number;
  nvComment: string;
  bDel: Boolean = false;
  bRefund: boolean;
  bImmediateCharge: boolean;
  bBlankCheck: boolean = false;
  bToReceipte :boolean = true;
  nvToReceipte :string ;

  iBankType: number;
  iBranchNumber: number;
  iaccountNumber: number;

  iDocumentNumber: number;
  /**
   *
   */
  constructor() {
    this.bToReceipte = true;
  }
}
