import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  ViewChild
} from "@angular/core";
import { Form, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ServService } from "../../../app/serv.service";
import { Family } from "../../../Models/Family";
import { ChildrenRoport } from "../../../Models/ChildrenReports";
import * as moment from "moment";
import "ag-grid-enterprise";
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { ReportsCodeTable } from "../../../Models/ReportsCodeTable";
import { AppProxy } from "../../../app/app.proxy";
import { CodeTable } from "../../../Models/CodeTable";
import { ReportsDet } from "../../../Models/ReportsDet";
import { DataTable } from "primeng/primeng";
import { concat } from "rxjs-compat/operator/concat";
import { dailog } from "src/app/Additions/dailog/dailog";

@Component({
  selector: "app-saved-reports",
  templateUrl: "./saved-reports.component.html",
  styleUrls: ["./saved-reports.component.scss"]
})
export class SavedReportsComponent implements OnInit {
  logoPath: string;
  SortArray: Array<any> = new Array<any>();
  ReportsDet: ReportsDet;
  nvImage: any;
  ImageUrl: string;
  columnParents;
  columnChildren;
  columnParentsAndChildren;
  lReportSubjectType: CodeTable;
  lReportsCodeTable: ReportsCodeTable[];
  ckeConfig: {
    allowedContent: boolean;
    extraPlugins: string;
    forcePasteAsPlainText: boolean;
  };
  mycontent: string = "";
  iYearId: number;
  iReportSubjectType: number = 65;
  iReportsCodeTable: number;
  backgroundColor: string;
  FilterArray: Array<any> = new Array<any>();
  public reportType: number;
  private column;
  rowData;
  public dailog: MatDialog;
  gridOptions;
  public gridColumnApi: any;
  private gridApi: any;
  private columnApi: any;
  columnTypes;
  private visible: boolean;
  private childrenReport: Array<ChildrenRoport>;
  currentiFamilyIdTemp;
  firstAfterNew: boolean = false;
  base64: string;
  lReportsDet: Array<ReportsDet> = new Array<ReportsDet>();
  filterModel: { [k: string]: any } = {};
  newReportName: string = '';
  constructor(
    public serv: ServService,
    private route: Router,
    private proxy: AppProxy,
    private dialog: MatDialog
  ) {
    this.reportType = 1;
    this.backgroundColor = this.serv.backgroundColor[3];
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(
                Number(dateParts[1]) +
                "/" +
                Number(dateParts[0]) +
                "/" +
                Number(dateParts[2])
              );
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };

    this.columnChildren = [
      {
        headerName: "שורה",
        field: "indexRow",
        cellRenderer: function (params) {
          let z = "<p>" + (params.node.rowIndex + 1) + "</p>";
          return z;
        },
        width: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "משפחה ",
        field: "nvLastName",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " פרטי",
        field: "nvFirstName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם חיבה",
        field: "nvNickname",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כיתה",
        field: "nvLevel",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה",
        field: "dBirthDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "מ.זהות",
        field: "nvIdentity",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה עברי",
        field: "nvJewBirthDate",
        width: 130,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "גיל",
        field: "iAge",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },

      {
        headerName: "ת.כניסה",
        field: "dBeginDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "עיר",
        field: "nvCityName",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvMobileF",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvMobileM",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'שכ"ל',
        field: "nvTuitionRateName",
        width: 110,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'סכום שכ"ל',
        field: "nTuitionAmount",
        width: 110,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הנחה",
        field: "nDiscountAmount",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נוסף",
        field: "nvMealsRateName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום תעריף נוסף",
        field: "nMealsAmount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה",
        field: "nvTranspRateName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום הסעה",
        field: "nTransAmount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "חיובים נוספים",
        field: "nAmount",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "ס'ה",
        field: "nTotal",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "סניף",
        field: "nvBranchName",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "סטטוס",
        field: "nvStatusName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      //============
      {
        headerName: "סמל מוסד",
        field: "nvInstitutionSymbol",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה הלוך",
        field: "nvTranspGoType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה חזור ",
        field: "nvTranspReturnType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה שישי",
        field: "nvTranspReturnFriType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות",
        field: "nvComments",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
    this.columnParents = [
      {
        headerName: "שורה",
        field: "indexRow",
        cellRenderer: function (params) {
          let z = "<p>" + (params.node.rowIndex + 1) + "</p>";
          return z;
        },
        width: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "משפחה",
        field: "nvFamilyName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האב",
        field: "nvFirstNameF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אב",
        field: "nvIdentityF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvMobileF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האם",
        field: "nvFirstNameM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אם",
        field: "nvIdentityM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvMobileM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מיקוד",
        field: "nvZipCode",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "קהילה",
        field: "nvCommunity",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מספר תלמידים",
        field: "iNumChildren",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },

      {
        headerName: "יתרה לתשלום",
        field: "nBalance",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הו''ק",
        field: "isDD",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "אשראי",
        field: "isCC",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      }
    ];
    this.columnParentsAndChildren = [
      {
        headerName: "פתיחה",
        field: "iFamilyId",
        width: 120,
        suppressFilter: true,
        cellRenderer: function (params) {
          var eDiv = document.createElement("div");
          eDiv.innerHTML =
            '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll("#edit")[0];
          eButton.addEventListener("click", function () {
            params.context.componentParent.editFamily(params.data);
          });
          return eDiv;
        }
      },
      {
        headerName: "שורה",
        field: "indexRow",
        cellRenderer: function (params) {
          let z = "<p>" + (params.node.rowIndex + 1) + "</p>";
          return z;
        },
        width: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "משפחה",
        field: "nvLastName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האב",
        field: "nvFirstNameF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אב",
        field: "nvIdentityF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvMobileF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האם",
        field: "nvFirstNameM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אם",
        field: "nvIdentityM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvMobileM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מיקוד",
        field: "nvZipCode",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "קהילה",
        field: "nvCommunity",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מספר תלמידים",
        field: "iNumChildren",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "יתרה לתשלום",
        field: "nBalance",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הו''ק",
        field: "isDD",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "אשראי",
        field: "isCC",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: " פרטי",
        field: "nvFirstName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם חיבה",
        field: "nvNickname",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כיתה",
        field: "nvLevel",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה",
        field: "dBirthDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "מ.זהות",
        field: "nvIdentity",
        width: 140,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה עברי",
        field: "nvJewBirthDate",
        width: 130,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "גיל",
        field: "iAge",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.כניסה",
        field: "dBeginDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },

      {
        headerName: 'שכ"ל',
        field: "nvTuitionRateName",
        width: 110,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: 'סכום שכ"ל',
        field: "nTuitionAmount",
        width: 110,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הנחה",
        field: "nDiscountAmount",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "נוסף",
        field: "nvMealsRateName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום תעריף נוסף",
        field: "nMealsAmount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה",
        field: "nvTranspRateName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום הסעה",
        field: "nTransAmount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "חיובים נוספים",
        field: "nAmount",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "ס'ה",
        field: "nTotal",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "סניף",
        field: "nvBranchName",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "סטטוס",
        field: "nvStatusName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סמל מוסד",
        field: "nvInstitutionSymbol",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה הלוך",
        field: "nvTranspGoType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה חזור ",
        field: "nvTranspReturnType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הסעה שישי",
        field: "nvTranspReturnFriType",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות",
        field: "nvComments",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
  }
  SaveReport() {

    if ((this.reportType == 1 && !this.newReportName) || (this.reportType == 2 && !this.iReportsCodeTable)) {
      // alert("have to show message ");
      this.dialog.open(dailog, {
        hasBackdrop: true,
        height: "200px",
        width: "300px",
        data: "אין לשמור דוח ללא שם דוח"
      });
      return;
    }

    if (this.gridColumnApi) {
      let lstColumn = this.gridColumnApi.getAllGridColumns();
      this.lReportsDet = new Array<ReportsDet>();
      lstColumn.forEach((col, index) => {
        this.ReportsDet = new ReportsDet();
        if (col.visible == true) {
          this.ReportsDet.nFieldWidth = col.actualWidth;
          // this.ReportsDet.nvFieldName = col.colId;
          this.ReportsDet.nvFieldName = col.colDef.field;
          this.ReportsDet.nvFieldTitle = col.colDef.headerName;
          this.lReportsDet.push(this.ReportsDet);
        }
      });
      let filterModel = [];
      for (
        let i = 0;
        i < Object.values(this.gridOptions.api.getFilterModel()).length;
        i++
      ) {
        filterModel.push({
          nvField: Object.keys(this.gridOptions.api.getFilterModel())[i],
          nvType: (Object.values(this.gridOptions.api.getFilterModel())[
            i
          ] as any).type,
          nvFilter: (Object.values(this.gridOptions.api.getFilterModel())[
            i
          ] as any).filter,
          nvFilterType: (Object.values(this.gridOptions.api.getFilterModel())[
            i
          ] as any).filterType
        });
      }
      // let newReportName = this.mycontent.split(">").find(s => !s.startsWith("<")).substring(0, this.mycontent.split(">").find(s => !s.startsWith("<")).indexOf("<"));
      this.proxy.post("InsReports", {
        iUserId: this.serv.user.iUserId,
        reports: {
          nvReportsName: this.newReportName,
          nvTitle: this.mycontent,
          iReportSubjectType: this.iReportSubjectType,
          // iParameterYearId: this.serv.iYearId,
          nvSortColId: this.gridOptions.api.getSortModel().length > 0 ? this.gridOptions.api.getSortModel()[0].colId : null,
          nvSortDirection: this.gridOptions.api.getSortModel().length > 0 ? this.gridOptions.api.getSortModel()[0].sort : null,
          filterModel: filterModel,
          logo: {
            nvFile: this.base64,
            nvFileName: this.nvImage,
            nvFilePath: ''
          },
          // sortModel: this.gridOptions.api.getSortModel(),
          lReportsDet: this.lReportsDet
        },
        nvGuide: this.serv.user.nvGuide,
        iInstitutionId: this.serv.user.iInstitutionId
      }).then(res => {
        this.lReportsCodeTable.push({ iReportSubjectType: this.iReportSubjectType, iReportsId: res, nvReportsName: this.newReportName });
        this.dialog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: "הדוח נשמר בהצלחה"
        });
      });
    }
  }
  printToPDF() {
    if (this.gridColumnApi) {
      let lstColumn = this.gridColumnApi.getAllGridColumns();
      this.lReportsDet = new Array<ReportsDet>();
      if (lstColumn)
        var string =
          '<DataTable xmlns="http://schemas.datacontract.org/2004/07/System.Data"><xs:schema id="NewDataSet" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns="" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:MainDataTable="dt" msdata:UseCurrentLocale="true"><xs:complexType><xs:choice minOccurs="0" maxOccurs="unbounded"><xs:element name="dt"><xs:complexType><xs:sequence>';

      lstColumn.forEach((col, index) => {
        this.ReportsDet = new ReportsDet();
        if (col.visible == true) {
          this.ReportsDet.nFieldWidth = col.actualWidth;
          this.ReportsDet.nvFieldName = col.colId;
          this.ReportsDet.nvFieldTitle = col.colDef.headerName;
          // this.ReportsDet.iFieldOrderby = 0;
          // this.ReportsDet.iFieldSequnce = 0;
          this.lReportsDet.push(this.ReportsDet);
        }
      });
      this.lReportsDet.forEach((col, colIndex) => {
        string +=
          '<xs:element name="a' +
          colIndex +
          '" type="xs:string" minOccurs="0"/>';
      });
      string +=
        '</xs:sequence></xs:complexType></xs:element></xs:choice></xs:complexType></xs:element></xs:schema><diffgr:diffgram xmlns:diffgr="urn:schemas-microsoft-com:xml-diffgram-v1" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><DocumentElement xmlns="">';
      this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(
        (element, dataIndex) => {
          string +=
            '<dt diffgr:id="dt' +
            dataIndex +
            '" msdata:rowOrder="' +
            dataIndex +
            '" diffgr:hasChanges="inserted">';

          this.lReportsDet.forEach((col, colIndex) => {
            if (col.nvFieldName == "indexRow")
              string +=
                "<a" + colIndex + ">" + dataIndex + "</a" + colIndex + ">";
            else
              string +=
                "<a" +
                colIndex +
                ">" +
                element.data[col.nvFieldName] +
                "</a" +
                colIndex +
                ">";
          });
          string += "</dt>";
        }
      );
      string += "</DocumentElement></diffgr:diffgram></DataTable>";
      this.serv
        .PrintToPDF(this.lReportsDet, string, this.mycontent, this.ImageUrl)
        .then(res => {
          var link = document.createElement("a");
          link.download = res;
          link.href = AppProxy.baseUrl + "Files/" + res;
          // link.click();
          window.open(link.href);
        });
    }
  }
  pinnedRowDataChanged(event) {
    //  console.log("pinnedRowDataChanged  ",event)
  }
  columnEverythingChanged() {
    // console.log("columnEverythingChanged")
  }
  newColumnsLoaded() {
    // console.log("newColumnsLoaded")
  }
  columnVisible(event) {
    // console.log("columnVisible  ",event)
    // this.gridApi.columnApi.getAllDisplayedColumns()
    // console.log("getAllDisplayedColumns  ",this.gridColumnApi.getAllDisplayedColumns())
    if (this.gridColumnApi.getAllDisplayedColumns().length == 0) {
      // console.log("empty  ")
      // this.addColumn();
      let column2 = this.gridColumnApi.getAllGridColumns();
      column2.forEach(element => {
        if (element.visible == false)
          this.gridOptions.columnApi.setColumnVisible(element.colId, false);
        if (element.colDef.headerName == 'עמודה')
          this.gridOptions.columnApi.setColumnVisible(element.colId, true);
      });
    }
    // params.columnApi.getAllDisplayedColumns()
  }
  filterChanged(params) {
    let t = this.gridOptions.api.getFilterModel();
    if (Object.values(t).length == 0 && this.firstAfterNew) {
      this.firstAfterNew = false;
      this.gridApi.setFilterModel(this.filterModel);
    }
    this.FilterArray.push(t);
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }
  sortChanged(event) {
    let t = this.gridOptions.api.getSortModel();
    this.SortArray.push(t);
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }
  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: "divarea",
      forcePasteAsPlainText: true
    };

    this.serv.visible = true;
    (this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      defaultColDef: {
        resizable: true
    },
      // domLayout: "forPrint",
      onGridReady: this.onGridReady
    }),
      this.GetReportsCodeTable();
    // this.GetReportsCodeTableBySubjectType(JSON.parse(localStorage.getItem("familyStatuses")))

    this.serv
      .GetChildrenReport(JSON.parse(localStorage.getItem("familyStatuses")))
      .then(res => {
        for (let i = 0; i < (res as any[]).length; i++) {
          if (res[i].dBeginDate != null)
            res[i].dBeginDate = moment(res[i].dBeginDate).format("DD/MM/YYYY");
          else
            res[i].dBeginDate = "";
          if (res[i].dBirthDate != null)
            res[i].dBirthDate = moment(res[i].dBirthDate).format("DD/MM/YYYY");
          else
            res[i].dBirthDate = "";
        }
        this.childrenReport = res;
        this.rowData = this.childrenReport;
      });
  }

  GetReportsCodeTableBySubjectType() {
    if (this.iReportSubjectType == 64) {
      //  this.column=this.columnParents;
      this.serv
        .GetParentsReport(JSON.parse(localStorage.getItem("familyStatuses")))
        .then(res => {
          if (res) {
            this.rowData = res;
            this.gridApi.setColumnDefs(this.columnParents);
          }
        });
    } else {
      //  this.column=this.columnChildren;
      this.serv
        .GetChildrenReport(JSON.parse(localStorage.getItem("familyStatuses")))
        .then(res => {
          for (let i = 0; i < (res as any[]).length; i++) {
            if (res[i].dBeginDate != null && res[i].dBirthDate != null) {
              res[i].dBeginDate = moment(res[i].dBeginDate).format(
                "DD/MM/YYYY"
              );
              res[i].dBirthDate = moment(res[i].dBirthDate).format(
                "DD/MM/YYYY"
              );
            } else {
              res[i].dBeginDate = null;
              res[i].dBirthDate = null;
            }
          }
          this.childrenReport = res;
          this.rowData = this.childrenReport;
          if (this.iReportSubjectType == 65)
            this.gridApi.setColumnDefs(this.columnChildren);
          if (this.iReportSubjectType == 71)
            this.gridApi.setColumnDefs(this.columnParentsAndChildren);
        });
    }
  }
  addColumn() {
    if (this.reportType == 1) {//דוח חדש
      if (this.iReportSubjectType == 71) {
        this.columnParentsAndChildren.push({
          headerName: " ",
          field: "4",
          width: 100,
          filter: "agTextColumnFilter"
        });
        this.gridApi.setColumnDefs(this.columnParentsAndChildren);
      }
      else if (this.iReportSubjectType == 65) {
        this.columnChildren.push({
          headerName: " ",
          field: "4",
          width: 100,
          filter: "agTextColumnFilter"
        });
        this.gridApi.setColumnDefs(this.columnChildren);
      }
      else {
        this.columnParents.push({
          headerName: " ",
          field: "4",
          width: 100,
          filter: "agTextColumnFilter"
        });
        this.gridApi.setColumnDefs(this.columnParents);
      }
    } else {
      this.gridApi.columnController.columnDefs.push({
        headerName: " ",
        field: "4",
        width: 100,
        filter: "agTextColumnFilter"
      });
      this.gridApi.setColumnDefs(this.gridApi.columnController.columnDefs);
    }
    let column2 = this.gridColumnApi.getAllGridColumns();
    column2.forEach(element => {
      if (element.visible == false)
        this.gridOptions.columnApi.setColumnVisible(element.colId, false);

    });
  }
  onBtExport() {
    // var params = {
    //   skipHeader: false,
    //   allColumns: false,
    //   fileName: "דוח שמור",
    //   sheetName: "xls"
    // };
    // this.gridOptions.api.exportDataAsCsv(params);
    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "דוח שמור"
    };
    this.gridApi.exportDataAsExcel(params);
  }

  exportDataAsExcel(params) {
    var excelParams = {
      skipHeader: false,
      allColumns: false,
      fileName: 'דוח שמור',
      skipHeaders: true,
    }
    this.gridApi.exportDataAsExcel(excelParams);
  }


  GetReportsCodeTable() {
    this.serv.GetReportsCodeTable().then(res => {
      this.lReportsCodeTable = res;
    });
  }
  GetReportsById() {
    this.serv.GetReports(this.iReportsCodeTable).then(savedRes => {
      // this.serv.iYearId = savedRes.iParameterYearId;
      this.iReportSubjectType = savedRes.iReportSubjectType;
      let sort = [];
      sort.push({
        colId: savedRes.nvSortColId,
        sort: savedRes.nvSortDirection
      });
      this.filterModel = {};
      savedRes.filterModel.forEach(f => {
        
        this.filterModel[f.nvField] = {
          filter: f.nvFilter,
          type: f.nvType,
          filterType: f.nvFilterType
        };
      });
      this.ImageUrl = AppProxy.baseUrl + "Files/" + savedRes.logo.nvFilePath;
      if (this.iReportSubjectType == 64) {
        this.serv
          .GetParentsReport(JSON.parse(localStorage.getItem("familyStatuses")))
          .then(res => {
            if (res) {
              this.rowData = res;
              let columnsList = [];
              savedRes.lReportsDet.forEach(det => {
                let column = this.columnParents.find(
                  c => c.field == det.nvFieldName
                );
                if (column) {
                  column.width = det.nFieldWidth;
                  columnsList.push(column);
                }
              });
              this.gridApi.setColumnDefs(columnsList);
              this.gridApi.setSortModel(sort);
              this.gridApi.setFilterModel(this.filterModel);
              this.firstAfterNew = true;
            }
          });
      } else {
        this.serv
          .GetChildrenReport(JSON.parse(localStorage.getItem("familyStatuses")))
          .then(res => {
            for (let i = 0; i < (res as any[]).length; i++) {
              
              // תאריך כניסה
              if (res[i].dBeginDate != null) {
                res[i].dBeginDate = moment(res[i].dBeginDate).format(
                  "DD/MM/YYYY"
                );
              } else {
                res[i].dBeginDate = null;
              }

              // תאריך לידה
              if (res[i].dBirthDate != null) {
                res[i].dBirthDate = moment(res[i].dBirthDate).format(
                  "DD/MM/YYYY"
                );
              } else {
                res[i].dBirthDate = null;
              }
            }
            this.childrenReport = res;
            this.rowData = this.childrenReport;

            if (this.iReportSubjectType == 65) {
              let columnsList = [];
              savedRes.lReportsDet.forEach(det => {
                let column = this.columnChildren.find(
                  c => c.field == det.nvFieldName
                );
                if (column) {
                  column.width = det.nFieldWidth;
                  columnsList.push(column);
                }
              });
              this.gridApi.setColumnDefs(columnsList);
              this.gridApi.setSortModel(sort);
              this.gridApi.setFilterModel(this.filterModel);
              this.firstAfterNew = true;
            }
            if (this.iReportSubjectType == 71) {
              let columnsList = [];
              savedRes.lReportsDet.forEach(det => {
                let column = this.columnParentsAndChildren.find(
                  c => c.field == det.nvFieldName
                );
                if (column) {
                  column.width = det.nFieldWidth;
                  columnsList.push(column);
                }
              });
              this.gridApi.setColumnDefs(columnsList);
              this.gridApi.setSortModel(sort);
              this.gridApi.setFilterModel(this.filterModel);
              this.firstAfterNew = true;
            }
          });
      }
      this.mycontent = savedRes.nvTitle;
    });
  }

  GetReports() { }
  getReport(lStatus) {
    this.serv.GetChildrenReport(lStatus).then(res => {
      for (let i = 0; i < (res as any[]).length; i++) {
        if (res[i].dBeginDate != null && res[i].dBirthDate != null) {
          res[i].dBeginDate = moment(res[i].dBeginDate).format("DD/MM/YYYY");
          res[i].dBirthDate = moment(res[i].dBirthDate).format("DD/MM/YYYY");
        } else {
          res[i].dBeginDate = "";
          res[i].dBirthDate = "";
        }
      }
      this.childrenReport = res;
      this.rowData = this.childrenReport;
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
  }
  getUrl() {
    return this.nvImage !== null
      ? `${this.proxy.docUrl}default.png`
      : this.ImageUrl;
  }

  selectLogo(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.ImageUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.fileChange(event);
    }
  }
  fileChange(event) {
    console.log((window as any).FileReader);
    let x = this;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var fileType = file.name.substring(
        file.name.indexOf(".") + 1,
        file.name.length
      );
      if ((window as any).FileReader) {
        var fileBase64 = "";
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        var fileName;
        fileReader.onload = function (e) {
          fileBase64 = (e.target as any).result.substring(
            (e.target as any).result.indexOf(",") + 1,
            (e.target as any).result.length
          );
          x.nvImage = file.name;
          x.base64 = fileBase64;
        };
      }
    }
  }
  getName() {
    return this.nvImage !== null ? `העלה לוגו` : "שנה לוגו";
  }
  deleteReport() {
    this.proxy.post("DelReports", {
      iReportsId: this.iReportsCodeTable,
      iUserId: this.serv.user.iUserId,
      nvGuide: this.serv.user.nvGuide,
      iInstitutionId: this.serv.user.iInstitutionId
    }).then(res => {
      let ind = this.lReportsCodeTable.indexOf(this.lReportsCodeTable.find(r => r.iReportsId == this.iReportsCodeTable));
      this.lReportsCodeTable.splice(ind, 1);
      this.dialog.open(dailog, {
        hasBackdrop: true,
        height: "200px",
        width: "300px",
        data: "הדוח נמחק בהצלחה"
      });
    })
  }
}
