import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Form, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ServService } from "../../../app/serv.service";
import { Family } from "../../../Models/Family";
import { ChildrenRoport } from "../../../Models/ChildrenReports";
import * as moment from "moment";
import "ag-grid-enterprise";
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { UpdateChildrenComponent } from "../../update-children/update-children.component";
import { ReportsDet } from "../../../Models/ReportsDet";
import { AppProxy } from "../../../app/app.proxy";

@Component({
  selector: "app-credit-report",
  templateUrl: "./credit-report.component.html",
  styleUrls: ["./credit-report.component.css"]
})
export class CreditReportComponent implements OnInit {
  dialogRef: any;
  backgroundColor: string;
  private column;
  private column1;
  private rowData;
  public rowData1;
  private gridOptions;
  private gridColumnApi: any;
  private gridApi: any;
  private columnTypes;
  private visible: boolean;
  private childrenReport: Array<ChildrenRoport>;
  public defaultColDef;
  private IsCreditbyassociation:boolean = false;
  isVisible = false;
  numRows=0;
  sumTotal=0;
  currentiFamilyIdTemp;
  ReportsDet: ReportsDet;
  lReportsDet: Array<ReportsDet> = new Array<ReportsDet>();
  dFromDate: Date = undefined;
  ngOnDestroy() {
    this.serv.currentiFamilyId = this.currentiFamilyIdTemp;
  }

  constructor(
    public serv: ServService,
    private route: Router,
    private updateChildDailog: MatDialog
  ) {
    this.backgroundColor = this.serv.backgroundColor[3];
  }

  ngOnInit() {
    this.serv.visible = true;
    this.dFromDate = new Date(
      JSON.parse(localStorage.getItem("dDebitFamilyFromDate"))
    );
    (this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      defaultColDef: {
        resizable: true
    },
      enableRtl: true,
      onGridReady: this.onGridReady,
      animateRows: true,
      /* filterChanged: this.filterChanged */
    }),
    this.defaultColDef = {
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true
    };
      (this.column = [
        {
          headerName: "מזהה תשלום",
          field: "iCreditId",
          width: 130,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "מזהה צ'ק",
          field: "iCheckId",
          width: 130,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "משפחה",
          field: "nvFamilyName",
          width: 160,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "טלפון",
          field: "nvPhone",
          width: 160,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "טלפון אב",
          field: "nvPhoneF",
          width: 160,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "טלפון אם",
          field: "nvPhoneM",
          width: 160,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "ת.ביצוע",
          field: "dTransactDate",
          type: "dateColumn",
          width: 155,
          comparator: this.dateComparator,
          sortable: true
        },

        // { headerName: "תאריך יצירה", field: "nvCreatedate", filter: "agDateColumnFilter", type: "dateColumn", hide: this.columnDefaultGrid["nvCreatedate"].hide, width: this.columnDefaultGrid["nvCreatedate"].width, indexColumn: this.columnDefaultGrid["nvCreatedate"].indexColumn },
        {
          headerName: "עבור חודש",
          field: "dCreditDate",
          type: "dateColumn",
          width: 155,
          comparator: this.dateComparator,
          sortable: true
        },
        {
          headerName: "תאריך צ'ק",
          field: "dCheckDate",
          type: "dateColumn",
          width: 155,
          comparator: this.dateComparator,
          sortable: true
        },
        {
          headerName: "סוג תשלום",
          field: "nvCreditType",
          width: 150,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "א. תשלום",
          field: "nvPaymentMethodType",
          width: 150,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "מ.חשבון/כרטיס",
          field: "nvPaymentMethodDetails",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "סכום",
          field: "nAmount",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },

        {
          headerName: "סטטוס",
          field: "nvStatusType",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "מ.אישור",
          field: "nvConfirmationNum",
          width: 130,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "הערות",
          field: "nvComment",
          width: 150,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "הערות למשפחה",
          field: "nvCommentsFamily",
          width: 150,
          filter: "agTextColumnFilter",
          sortable: true
        }
        ,      
      {
        headerName: "עמותה",
        field: "nvAssociationName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עבור קבלה",
        field: "nvToReceipte",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מספר קבלה",
        field: "iDocumentNumber",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true        
      }
      ]);
    this.getReport();
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive:true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(
                Number(dateParts[1]) +
                "/" +
                Number(dateParts[0]) +
                "/" +
                Number(dateParts[2])
              );
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            }
            else {
              return -1
            }
          }
        }
      }
    };
  }
  dateComparator(date1, date2) {
    if (date1 === null && date2 === null) {
      return 0;
    }
    if (date1 === null) {
      return -1;
    }
    if (date2 === null) {
      return 1;
    }
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    return cellDate1.getTime() - cellDate2.getTime();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    // if (this.gridColumnApi.columnDefs(0).field == 'dMonth'){
    //   this.gridApi.sizeColumnsToFit();
    // }
    // if (params.alldisplayedcolumns(0).colid = "dMonth")
    // {
    //   params.api.sizeColumnsToFit();
    // }

  } 
  onfilterChanged(params) {
    let t = this.gridOptions.api.getFilterModel();
    /*     console.log("params", t);
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    }); */
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows=0;
    this.sumTotal=0;
    filteredRows.forEach(t=>{
      this.sumTotal+=t.data.nAmount;
      this.numRows++;
    })
  }
  sortChanged() {
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }
  getReport() {
    // alert("children")
    this.numRows=0;
    this.sumTotal=0;  
    this.serv.GetCreditReport(this.dFromDate).then(res => {
      for (let i = 0; i < (res as any[]).length; i++) {
        if (res[i].dTransactDate != null) {
          res[i].dTransactDate = moment(res[i].dTransactDate).format(
            "DD/MM/YYYY"
          );
        } else {
          res[i].dTransactDate = null;
        }
        if (res[i].dCreditDate != null) {
          res[i].dCreditDate = moment(res[i].dCreditDate).format("DD/MM/YYYY");
        } else {
          res[i].dCreditDate = "";
        }
        if (res[i].dCheckDate != null) {
          res[i].dCheckDate = moment(res[i].dCheckDate).format("DD/MM/YYYY");
        } else {
          res[i].dCheckDate = "";
        }
        this.sumTotal+=res[i].nAmount;
        this.numRows++;  
      }
      this.rowData = res;
    });
  }
  updateDetails(params) {
    this.dialogRef = this.updateChildDailog.open(UpdateChildrenComponent, {
      hasBackdrop: true,
      height: "800px",
      width: "1400px",
      data: { iChildId: params.iChildId }
    });
  }
  editChild() {
    // this.serv.currentiFamilyId = Family.iFamilyId
    // this.serv.currentFamily = Family;
    // this.router.navigateByUrl('/header/families/tabs/parentDetails');
    // this.serv.visible = false
  }
  onBtExport() {
    // var params = {
    //   skipHeader: false,
    //   allColumns: false,
    //   fileName: "פרוט תשלומים",
    //   sheetName: "xls"
    // };

    // if (getBooleanValue('#skipGroupR')) {
    //     params.shouldRowBeSkipped = function(params) {
    //         return params.node.data.country.charAt(0) === 'R'
    //     };
    // }
    // this.gridOptions.api.exportDataAsExcel(params);

    // this.gridOptions.api.exportDataAsCsv(params);


    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "פרוט תשלומים"
    };
    this.gridApi.exportDataAsExcel(params);
  }
  printToPDF() {
    // this.lData=new ;
    if (this.gridColumnApi) {
      let lstColumn = this.gridColumnApi.getAllGridColumns();
      this.lReportsDet = new Array<ReportsDet>();
      if (lstColumn)
        var string =
          '<DataTable xmlns="http://schemas.datacontract.org/2004/07/System.Data"><xs:schema id="NewDataSet" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns="" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:MainDataTable="dt" msdata:UseCurrentLocale="true"><xs:complexType><xs:choice minOccurs="0" maxOccurs="unbounded"><xs:element name="dt"><xs:complexType><xs:sequence>';

      lstColumn.forEach((col, index) => {
        this.ReportsDet = new ReportsDet();
        if (col.visible == true) {
          this.ReportsDet.nFieldWidth = col.actualWidth;
          this.ReportsDet.nvFieldName = col.colId;
          this.ReportsDet.nvFieldTitle = col.colDef.headerName;
          // this.ReportsDet.iFieldOrderby = 0;
          // this.ReportsDet.iFieldSequnce = 0;
          this.lReportsDet.push(this.ReportsDet);
        }
      });
      this.lReportsDet.forEach((col, colIndex) => {
        string +=
          '<xs:element name="a' +
          colIndex +
          '" type="xs:string" minOccurs="0"/>';
      });
      string +=
        '</xs:sequence></xs:complexType></xs:element></xs:choice></xs:complexType></xs:element></xs:schema><diffgr:diffgram xmlns:diffgr="urn:schemas-microsoft-com:xml-diffgram-v1" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><DocumentElement xmlns="">';
      this.gridOptions.gridApi.rowModel.rowsToDisplay.forEach(
        (element, dataIndex) => {
          string +=
            '<dt diffgr:id="dt' +
            dataIndex +
            '" msdata:rowOrder="' +
            dataIndex +
            '" diffgr:hasChanges="inserted">';

          this.lReportsDet.forEach((col, colIndex) => {
            if (col.nvFieldName == "indexRow")
              string +=
                "<a" + colIndex + ">" + dataIndex + "</a" + colIndex + ">";
            else
              string +=
                "<a" +
                colIndex +
                ">" +
                element.data[col.nvFieldName] +
                "</a" +
                colIndex +
                ">";
          });
          string += "</dt>";
        }
      );
      string += "</DocumentElement></diffgr:diffgram></DataTable>";
      this.serv.PrintToPDF(this.lReportsDet, string, "דוח תשלומים", null).then(res => {
        console.log(res);
        var link = document.createElement("a");
        link.download = res;
        link.href = AppProxy.baseUrl + "Files/" + res;
        window.open(link.href);
      });
    }
  }

  Creditbyassociation() {
    this.serv.GetSumCreditByAssociation().then(res => {
      for (let i = 0; i < (res as any[]).length; i++) {
        if (res[i].dMonth != null) {
          res[i].dMonth = moment(res[i].dMonth).format(
            "DD/MM/YYYY"
          );
        } else {
          res[i].dMonth = null;
        }
      }
      this.isVisible = true;
      this.IsCreditbyassociation = true;
      this.column1 = [
        {
          headerName: "חודש",
          field: "dMonth",
          type: "dateColumn",
          width: 155,
          comparator: this.dateComparator,
          sortable: true
        },
        {
          headerName: "שם עמותה",
          field: "nvAssociationName",
          //width: 160,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "אופן תשלום",
          field: "nvPaymentMethodType",
          //width: 150,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סטטוס",
          field: "nvStatusType",
          //width: 120,
          sortable: true,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סך הכל",
          field: "nSumAmount",
          type:"numberColumn",
         // valueFormatter: params => params.data.currency.tofixed(2),
          //width: 120,
          sortable: true,
          filter: "agTextColumnFilter"
        },
      ];
      this.rowData1 = res;
    });
  }
  printPdf() {
    let str = ''
    this.serv.printToPDF1(this.gridOptions.columnApi, this.gridOptions, str, null);
  }

}
