import { Component, OnInit } from '@angular/core';
import { LOCALE_TEXT } from '../../app/Additions/constants';
import { ServService } from '../../app/serv.service';
// import { GridApi } from 'ag-grid';
import { FamilyContacts } from '../../Models/FamilyContacts';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {dailog} from '../../app/Additions/dailog/dailog'
@Component({
  selector: 'app-family-contacts',
  templateUrl: './family-contacts.component.html',
  styleUrls: ['./family-contacts.component.css']
})
export class FamilyContactsComponent implements OnInit {

  backgroundColor: string;
  public columnDefs;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;

  isVisible = false;
  contact: FamilyContacts
  form: FormGroup
  alertResponse = '';
  displayAlert = false;
  isEdit = false;

  constructor(public serv: ServService, private dailog: MatDialog) {
    this.contact = new FamilyContacts();
    this.backgroundColor = this.serv.backgroundColor[0];
  }

  ngOnInit() {


    this.gridOptions = {
      localeText: { LOCALE_TEXT },
      context: { componentParent: this },
      defaultColDef: {
        resizable: true
    },
      // onGridReady: this.onGridReady,
      enableRtl: true
    },
      this.columnDefs = [
        {
          headerName: "עריכה",
          field: "iUserId",
          width: 100,
          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML = '<button class="btn-icon-grid" id="edit"><span class="icon-edit"></span></button>';
            var eButton = eDiv.querySelectorAll('#edit')[0];
            eButton.addEventListener('click', function () {
              params.context.componentParent.editContact(params.data);
            });

            return eDiv;
          }
        },
        {
          headerName: "קרבה",
          field: "nvRelationshipType",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "משפחה ",
          field: "nvLastName",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "שם פרטי",
          field: "nvFirstName",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "טלפון ",
          field: "nvPhone",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "נייד",
          field: "nvMobile",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "רחוב",
          field: "nvAddress",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "בית",
          field: "nvNumHouse",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "שכונה",
          field: "nvNeighborhood",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "עיר",
          field: "nvCityName",
          width: 140,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "מיקוד",
          field: "nvZipCode",
          width: 140,
          filter: "agTextColumnFilter"
        }]

    this.GetGrandParent();

    this.form = new FormGroup({
      iRelationshipType: new FormControl(),
      nvFirstName: new FormControl(),
      nvLastName: new FormControl(),
      nvPhone: new FormControl(),
      nvMobile: new FormControl(),
      iCityType: new FormControl(),
      nvAddress: new FormControl(),
      nvNumHouse: new FormControl(),
      nvNeighborhood: new FormControl(),
      nvZipCode: new FormControl()
    });


  }
  changeValidator(){
    // console.log(this.contact.nvLastName)
    if(!!this.contact.nvLastName){
        this.form.controls["nvPhone"].clearValidators();
        this.form.controls["nvPhone"].updateValueAndValidity();
    }else if(!!this.contact.nvPhone){
      this.form.controls["nvLastName"].clearValidators();
      this.form.controls["nvLastName"].updateValueAndValidity();
    }else{
      this.form.controls["nvPhone"].setValidators([Validators.required]);
      this.form.controls["nvPhone"].updateValueAndValidity();
      this.form.controls["nvLastName"].setValidators([Validators.required]);
      this.form.controls["nvLastName"].updateValueAndValidity();
    }
  }
  onGridReady(params) {
    // this.gridApi.setRowData(this.rowData);
    // GridApi
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }

  GetGrandParent() {
    this.serv.GetGrandParent().then(res => {
      if (res) {
        console.log("res " + res);
        this.rowData = res;
        // this.gridApi.setRowData(this.rowData);
      }
    });
  }

  addContacts() {
    this.contact = new FamilyContacts();
    this.isVisible = true;
    // this.form.controls["nvPhone"].setValidators([Validators.required]);
    //   this.form.controls["nvPhone"].updateValueAndValidity();
    //   this.form.controls["nvLastName"].setValidators([Validators.required]);
    //   this.form.controls["nvLastName"].updateValueAndValidity();
  }

  InsUpdGrandParent() {
    if (this.form.valid == false) {
      this.dailog.open(dailog, {
        hasBackdrop: true,
        height: "200px",
        width: "300px",
        data: "יש למלא את כל שדות החובה"
      });
      return;
    }
    // if(this.form.controls)
    // console.log(this.form.controls)
    let containsValue=false;
      Object.keys(this.form.controls).forEach(key=>{
        if(this.form.controls[key].value)
            {
              containsValue=true;

            }
      })
    if(!containsValue){
      this.isVisible = false;
      return;
    }

    if (!this.isEdit)
      this.contact.iGrandParentId = -1;
    this.serv.InsUpdGrandParent(this.contact).then(res => {
      if (res) {
        if (!this.isEdit) {
          this.GetGrandParent();
          this.isEdit = false;
        }
        this.isVisible = false;
        this.gridApi.setRowData(this.rowData);
      }
    });
  }


  editContact(params) {
    this.isEdit = true;
    this.isVisible = true;
    this.contact = params;
    // this.form.controls["nvPhone"].setValidators([Validators.required]);
    //   this.form.controls["nvPhone"].updateValueAndValidity();
    //   this.form.controls["nvLastName"].setValidators([Validators.required]);
    //   this.form.controls["nvLastName"].updateValueAndValidity();
  }


}
