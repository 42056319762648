import { Component, OnInit, ViewChild } from '@angular/core';
import { ServService } from '../../app/serv.service';
import { paymentDetails } from '../../Models/paymentDetails';
import { Location } from '@angular/common';
import * as moment from "moment"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from "rxjs";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import 'rxjs/add/operator/takeWhile';
import { MatDialog } from '@angular/material/dialog';
import { dailog } from 'src/app/Additions/dailog/dailog';
@Component({
  selector: 'app-details-payment',
  templateUrl: './details-payment.component.html',
  styleUrls: ['./details-payment.component.css']
})
export class DetailsPaymentComponent implements OnInit {
  isIFrameUrl: boolean = false
  bFilter: boolean = false
  public IFrameUrl: string;
  alertResponse = '';
  displayAlert = false;
  backgroundColor: string;
  pDetails: Array<paymentDetails>
  dd: Date
  isVisible
  form: FormGroup
  iDebitDay: number
  iCanceledCount: number
  @ViewChild('f', { static: true }) myNgForm;
  pattern = "[0-9]*"


  constructor(public service: ServService, private dailog: MatDialog, private location: Location) {
    this.pDetails = new Array<paymentDetails>();
    this.backgroundColor = this.service.backgroundColor[0];
  }
  ngOnInit() {

    this.service.visible = false;
    this.form = new FormGroup({
      iPaymentMethodType: new FormControl("", Validators.required),
      // iDebitDay: new FormControl("", Validators.required),
      ipriorityType: new FormControl(),//"", [Validators.required, Validators.min(1), Validators.max(20)]),
      dPausedToDate: new FormControl(),
      nFixedAmount: new FormControl(),
      nMaxAmount: new FormControl(),
      iBankType: new FormControl(),
      iBranchNumber: new FormControl(),
      iaccountNumber: new FormControl(),
      nvCard: new FormControl("",[Validators.minLength(4)]),
      dExpirationDate: new FormControl("", [Validators.minLength(4), Validators.maxLength(4)]),
      CVV: new FormControl("", [Validators.minLength(3), Validators.maxLength(4)]),
      iAssociationId: new FormControl(),
      iStatusType: new FormControl("", Validators.required),
      iRateType: new FormControl(),
      bExternalCharge: new FormControl(),
      nvAccountName: new FormControl(),
      nvAccountIdentity: new FormControl("", [Validators.maxLength(9)]),
      nvCardName: new FormControl(),
      newPDetailsDebitDay: new FormControl(),
    })
    if (this.service.currentiFamilyId != -1)
      this.getPaymentDetails();

  }

  ngDoCheck() {
    if (this.service.newPDetails.nFixedAmount > 0) {
      this.form.controls["iAssociationId"].setValidators([Validators.required])
      this.form.controls["iRateType"].disable();
      this.form.controls["nMaxAmount"].disable();

    }
    if (this.service.newPDetails.nMaxAmount > 0) {
      this.form.controls["iAssociationId"].setValidators([Validators.required])
      this.form.controls["iRateType"].disable();
      this.form.controls["nFixedAmount"].disable();

    }
    else {
      this.form.controls["iAssociationId"].setValidators([])
      this.form.controls["iRateType"].enable();
      this.form.controls["nMaxAmount"].enable();

    }
    if (this.service.newPDetails.iRateType > 0) {

      this.form.controls["nFixedAmount"].disable();
      this.form.controls["nMaxAmount"].disable();

    }
    else {
      this.form.controls["nFixedAmount"].enable();
      this.form.controls["nMaxAmount"].enable();
    }
    if (this.service.newPDetails.iPaymentMethodType == 14) {
      this.form.controls["iAssociationId"].setValidators([Validators.required])
    }
  }

  filterPayment(checked: boolean) {
    if (checked) {
      this.service.GetPaymentDetails(this.service.user.iUserId, this.service.currentiFamilyId, checked)
        .then(res => {

          for (let i = 0; i < res.lPaymentDetails.length; i++) {
            // if (res.lPaymentDetails[i].dPausedToDate)
            //   res.lPaymentDetails[i].dPausedToDate = moment(res.lPaymentDetails[i].dPausedToDate).format("DD/MM/YYYY");
            if (res.lPaymentDetails[i].dExpirationDate)
              res.lPaymentDetails[i].dExpirationDate = moment(res.lPaymentDetails[i].dExpirationDate).format("DD/MM/YYYY");

          }

          this.iDebitDay = res.iDebitDay;
          this.pDetails = res.lPaymentDetails;

        })
    }
    else {
      this.getPaymentDetails()
    }
  }

  getPaymentDetails() {
    this.service.GetPaymentDetails(this.service.user.iUserId, this.service.currentiFamilyId, false)
      .then(res => {
        for (let i = 0; i < res.lPaymentDetails.length; i++) {
          // if (res.lPaymentDetails[i].dPausedToDate)
          //   res.lPaymentDetails[i].dPausedToDate = moment(res.lPaymentDetails[i].dPausedToDate).format("DD/MM/YYYY");
          if (res.lPaymentDetails[i].dExpirationDate)
            res.lPaymentDetails[i].dExpirationDate = moment(res.lPaymentDetails[i].dExpirationDate).format("DD/MM/YYYY");

        }
        this.iDebitDay = res.iDebitDay;
        this.pDetails = res.lPaymentDetails;
        this.iCanceledCount = res.iCanceledCount;
      })
  }

  changeType(data) {
    if (data == 13) {
      this.service.visibleCreditCard = true
      this.service.visibleDirectDebit = false
    }
    else if (data == 14) {
      this.service.visibleCreditCard = false
      this.service.visibleDirectDebit = true
    }
  }

  save() {
    if (this.form.valid == false) {
      this.alertResponse = 'יש למלא את כל שדות החובה';
      this.displayAlert = true;
      return;
    }
    if (this.service.newPDetails.iPaymentMethodType == 13)
      if (this.service.newPDetails.nvExpirationDate != this.service.nvExpDate)
        this.service.newPDetails.bExpirationDateUpdated = true;
    if (this.service.isReadOnly == false) {
      if (this.service.newPDetails.iPaymentMethodType == 13) {
        if (this.service.default1.iCCCompanyType == 60)
          this.GetTokenPeleCard();
        else this.GetTokenZCredit()
      } else {
        this.service.saveAndInsertPaymentDetails(this.service.user.iUserId, this.service.currentiFamilyId, this.service.newPDetails, [], this.service.default1.iCCCompanyType).then(res => {
          this.getPaymentDetails();
          this.service.isFormPaymentVisible = false
        });
      }
    }
    else {
      if (this.service.newPDetails.nFixedAmount == "")
        this.service.newPDetails.nFixedAmount = null
      if (this.service.newPDetails.nMaxAmount == "")
        this.service.newPDetails.nMaxAmount = null
      this.service.isReadOnly = false;
      this.service.isFormPaymentVisible = false
      this.service.saveAndInsertPaymentDetails(this.service.user.iUserId, this.service.currentiFamilyId, this.service.newPDetails, [], this.service.default1.iCCCompanyType).then(res => {
        this.getPaymentDetails();
      });
    }

  }

  addPayment() {
    this.service.isReadOnly = false;
    this.service.newPDetails = new paymentDetails()
    this.myNgForm.resetForm();
    this.isVisible = true
    this.service.isFormPaymentVisible = true
    if (this.pDetails.length > 0)
      this.service.newPDetails.ipriorityType = this.pDetails[this.pDetails.length - 1].ipriorityType + 1
    else this.service.newPDetails.ipriorityType = 1
    this.service.newPDetails.iStatusType = 18;
  }

  onSelectDebitDay() {
    this.service.UpdDebitDay(this.iDebitDay, this.service.currentiFamilyId).then(res => {
      if (res == "success") {
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: "נשמר בהצלחה!"
        });
      } else if (res == "error") {
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: "ארעה שגיאה בלתי צפויה"
        });
      } else {
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: res
        });
      }
    });
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      return
      // alert('you just clicked enter');
      // rest of your code
    }
  }

  onChangePaymentMethod(typeMethod) {
    let ipriorityType = this.service.newPDetails.ipriorityType;
    this.service.newPDetails = new paymentDetails();
    this.service.newPDetails.ipriorityType = ipriorityType;
    this.service.newPDetails.iPaymentMethodType = typeMethod;

      //הפריע לולידציה של מינימום 5 ספרות במס כרטיס -לא ברור    
    // this.form.controls["nvCard"].clearValidators();
    // this.form.controls["nvCard"].updateValueAndValidity();

    this.form.controls["dExpirationDate"].clearValidators();
    this.form.controls["dExpirationDate"].updateValueAndValidity();

    this.form.controls["iBranchNumber"].clearValidators();
    this.form.controls["iBranchNumber"].updateValueAndValidity();

    this.form.controls["iaccountNumber"].clearValidators();
    this.form.controls["iaccountNumber"].updateValueAndValidity();

    this.form.controls["iBankType"].clearValidators();
    this.form.controls["iBankType"].updateValueAndValidity();

    this.form.controls["iAssociationId"].clearValidators();
    this.form.controls["iAssociationId"].updateValueAndValidity();

    this.form.controls["newPDetailsDebitDay"].clearValidators();
    this.form.controls["newPDetailsDebitDay"].updateValueAndValidity();

    if (typeMethod == 13)//credit card
    {
      //הפריע לולידציה של מינימום 5 ספרות במס כרטיס ולא ברור
      // this.form.controls["nvCard"].setValidators([Validators.required]);
      // this.form.controls["nvCard"].updateValueAndValidity();

      this.form.controls["dExpirationDate"].setValidators(Validators.required);
      this.form.controls["dExpirationDate"].updateValueAndValidity();
    }
    else {


      this.form.controls["iBranchNumber"].setValidators(Validators.required);
      this.form.controls["iBranchNumber"].updateValueAndValidity();

      this.form.controls["iaccountNumber"].setValidators(Validators.required);
      this.form.controls["iaccountNumber"].updateValueAndValidity();

      this.form.controls["iBankType"].setValidators(Validators.required);
      this.form.controls["iBankType"].updateValueAndValidity();

      this.form.controls["iAssociationId"].setValidators(Validators.required);
      this.form.controls["iAssociationId"].updateValueAndValidity();


    }


  }
  GetTokenPeleCard() {

    this.service.GetTokenPeleCard(this.service.newPDetails.nvCard, this.service.newPDetails.nvExpirationDate).then(res => {
      console.log(res)
      if (res.length > 0 && res[0].StatusCode <= 0) {
        let PaymentMethodToken = [];
        this.service.isFormPaymentVisible = false
        res.forEach(element => {
          PaymentMethodToken.push({ iAssociationId: element.iAssociationId, nvTokenNumber: element.ResultData.Token });

        });
        this.service.newPDetails.nvCardLastFourDigits = this.service.newPDetails.nvCard.substr(this.service.newPDetails.nvCard.length - 4, 4);
        this.service.newPDetails.nvCardName = res[0].ResultData.CardHebName;
        this.service.saveAndInsertPaymentDetails(this.service.user.iUserId, this.service.currentiFamilyId, this.service.newPDetails, PaymentMethodToken, this.service.default1.iCCCompanyType).then(res => {
          this.getPaymentDetails();

        });
      };
    });
  }

  GetTokenZCredit() {
    // if()
    this.service.GetTokenZCredit(this.service.newPDetails.nvCard, this.service.newPDetails.iCVV ? this.service.newPDetails.iCVV.toString() : null, this.service.newPDetails.nvAccountIdentity, this.service.newPDetails.nvExpirationDate).then(res => {
      console.log(res)
    //  let res=[]
    //  res[0]={
    //     "ErrorMessage": "חסרים נתונים חיוניים - ,Pan",
    //     "StatusCode": 418,
    //     "Token": "",
    //     "iAssociationId": 36,
    //     "iCreditId": 0,
    //     "nvCardName": "",
    //     "nvCardNumber": "",
    //     "nvConfirmationNum": "",
    //     "nvExpDate": "01/21"
    //     }
    //   debugger
      if (res.length > 0 && res[0].StatusCode <= 0) {
        let PaymentMethodToken = [];
        this.service.isFormPaymentVisible = false
        res.forEach(element => {
          PaymentMethodToken.push({ iAssociationId: element.iAssociationId, nvTokenNumber: element.Token });

        });
        this.service.newPDetails.nvCardName = res[0].nvCardName;
        this.service.newPDetails.nvCardLastFourDigits = this.service.newPDetails.nvCard.substr(this.service.newPDetails.nvCard.length - 4, 4);
        this.service.saveAndInsertPaymentDetails(this.service.user.iUserId, this.service.currentiFamilyId, this.service.newPDetails, PaymentMethodToken, this.service.default1.iCCCompanyType).then(res => {
          this.getPaymentDetails();

        });
      };
    });
  }

  keyPress(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

}



