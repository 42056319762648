import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Form, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ServService } from "../../../app/serv.service";
import { LOCALE_TEXT } from "../../../app/Additions/constants";
import { Family } from "../../../Models/Family";
import * as moment from "moment"
// import { jsonpCallbackContext } from "@angular/common/http/src/module";

@Component({
  selector: "app-parents-list.component",
  templateUrl: "./parents-list.component.html",
  styleUrls: ["./parents-list.component.css"]
})
export class ParentsListComponent implements OnInit {
  backgroundColor: string;
  private column;
  private rowData;
  public gridOptions;
  public gridColumnApi: any;
  private gridApi: any;
  private columnTypes;
  private visible: boolean;
  private families: Array<Family>;
  numRows=0;
  sumTotal=0;
  sumBalance=0;
  currentiFamilyIdTemp;

  public static parentListFilterState=null;
  public static parentListSortState=null;
  public static parentListGroupState=[];
  public static parentListColState=null;

  ngOnDestroy() {
    this.serv.currentiFamilyId = this.currentiFamilyIdTemp;
  }

  constructor(public serv: ServService, private router: Router) {
    this.backgroundColor = this.serv.backgroundColor[3];
  }

  ngOnInit() {
    this.serv.visible = true;
    this.serv.bfromParentsList = false;
    // this.getFamilys();
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      defaultColDef: {
        resizable: true
    },
      onGridReady: this.onGridReady

      // DomLayout : "forPrint"
    };
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(Number(dateParts[1]) + "/" + Number(dateParts[0]) + "/" + Number(dateParts[2]));
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
    this.column = [
      {
        headerName: "פתיחה",
        field: "iFamilyId",
        width: 120,
        suppressFilter: true,
        cellRenderer: function (params) {
          var eDiv = document.createElement("div");
          eDiv.innerHTML =
            '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll("#edit")[0];
          eButton.addEventListener("click", function () {
            params.context.componentParent.editFamily(params.data);
          });

          return eDiv;
        }
      },
      {
        headerName: "שורה",
        field: "indexRow",
        cellRenderer: function (params) {
          let z = "<p>" + (params.node.rowIndex + 1) + "</p>";
          return z;
        },
        width: 100,
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "משפחה",
        field: "nvFamilyName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האב",
        field: "nvFirstNameF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אב",
        field: "nvIdentityF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה אב",
        field: "dBirthDateF",
        width: 120,
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "נייד אב",
        field: "nvMobileF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceF",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת עבודה",
        field: "nvWorkAddressF",
        width: 135,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שם האם",
        field: "nvFirstNameM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מ.ז. אם",
        field: "nvIdentityM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "ת.לידה אם",
        field: "dBirthDateM",
        width: 120,
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "נייד אם",
        field: "nvMobileM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " עבודה",
        field: "nvWorkPlaceM",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת עבודה",
        field: "nvWorkAddressM",
        width: 135,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "כתובת",
        field: "nvAddress",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "בית",
        field: "nvNumHouse",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עיר",
        field: "nvCityName",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "שכונה",
        field: "nvNeighborhood",
        width: 100,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "טלפון",
        field: "nvPhone",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מיקוד",
        field: "nvZipCode",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "דואל",
        field: "nvEmail",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מספר תלמידים",
        field: "iNumChildren",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },

      {
        headerName: "יתרה לתשלום",
        field: "nBalance",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "הו''ק",
        field: "isDD",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "אשראי",
        field: "isCC",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "יום חיוב",
        field: "nvDebitDay",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },          
      {
        headerName: 'שכר לימוד',
        field: "nTuitionAmount",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },      
      {
        headerName: 'הסעה',
        field: "nTransAmount",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: 'תעריף נוסף',
        field: "nMealsAmount",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      }, 
      {
        headerName: 'חיובים נוספים',
        field: "nAmount",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      }, 
      {
        headerName: 'ס"ה לתשלום החודש',
        field: "nSumTotal",
        width: 120,
        filter: "agNumberColumnFilter",
        sortable: true
      },
      {
        headerName: "סמל מוסד",
        field: "nvInstitutionSymbol",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות",
        field: "nvComments",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
   
    if(this.gridOptions.api)
    this.gridOptions.api.setFilterModel(ParentsListComponent.parentListFilterState);
    this.getReport(JSON.parse(localStorage.getItem("familyStatuses")));
    
  }
  rowDataChanged(event){
    if(this.gridOptions.api)
    this.gridOptions.api.setFilterModel(ParentsListComponent.parentListFilterState);
  }
  filterChanged(params) {
    // let t = this.gridOptions.api.getFilterModel();
    // t.operator = t.operator == "OR" ? "AND" : "OR";
    //  this.gridOptions.api.setFilterModel(t);
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows=0;
    this.sumTotal=0;
    this.sumBalance=0;
     filteredRows.forEach(t=>{
       this.sumTotal+=t.data.nSumTotal;
       this.sumBalance+=t.data.nBalance;
       this.numRows++;
      })
  }
  sortChanged() {
    this.gridOptions.api.forEachNode(function (node: any) {
      node.setDataValue("indexRow", node.rowIndex + 1);
    });
  }
  onBtExport() {
    // var params = {
    //   skipHeader: false,
    //   allColumns: false,
    //   fileName: "פרטי תלמידים",
    //   sheetName: "xls"
    // };
    // this.gridOptions.api.exportDataAsCsv(params);
    var params = {
      skipHeader: false,
      allColumns: false,
      fileName: "רשימת הורים"
    };
    this.gridApi.exportDataAsExcel(params);
  }

  getReport(lStatus) {
    this.serv.GetParentsReport(lStatus).then(res => {
      if (res) {
        this.numRows=0;
        this.sumTotal=0;
        this.sumBalance=0;
        for (let i = 0; i < (res as any[]).length; i++) {
          res[i].dBirthDateF = res[i].dBirthDateF != null ? moment(res[i].dBirthDateF).format("DD/MM/YYYY") : "";
          res[i].dBirthDateM = res[i].dBirthDateM != null ? moment(res[i].dBirthDateM).format("DD/MM/YYYY") : "";
          this.sumTotal+=res[i].nSumTotal;
          this.sumBalance+=res[i].nBalance;
          this.numRows++;
        }
        this.rowData = res;
        if(this.gridOptions.api)
             this.gridOptions.api.setFilterModel(ParentsListComponent.parentListFilterState);
      }
    });
  }
  editFamily(Family) {
    this.serv.currentiFamilyId = Family.iFamilyId;
    this.serv.currentFamily = Family;

    ParentsListComponent.parentListColState = this.gridOptions.columnApi.getColumnState();
    ParentsListComponent.parentListGroupState = this.gridOptions.columnApi.getColumnGroupState();
    ParentsListComponent.parentListSortState = this.gridOptions.api.getSortModel();
    ParentsListComponent.parentListFilterState = this.gridOptions.api.getFilterModel();
    
    this.router.navigateByUrl("/header/families/tabs/parentDetails");
    this.serv.bfromParentsList = true;
    this.serv.visible = false;
  }
  fitColumn() {
    console.log(
      "bodyWidth",
      this.gridOptions.gridApi.columnController.bodyWidth
    );
    // this.gridOptions.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();

    if(this.serv){
      if(ParentsListComponent.parentListColState)
      this.gridOptions.columnApi.setColumnState(ParentsListComponent.parentListColState);
      if(ParentsListComponent.parentListGroupState[0])
      this.gridOptions.columnApi.setColumnGroupState(ParentsListComponent.parentListGroupState);
      if(ParentsListComponent.parentListSortState)
      this.gridOptions.api.setSortModel(ParentsListComponent.parentListSortState);
      if(ParentsListComponent.parentListFilterState)
      this.gridOptions.api.setFilterModel(ParentsListComponent.parentListFilterState);
    }
    
 
  }
}
