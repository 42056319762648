import { Component, OnInit } from '@angular/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { ServService } from '../../app/serv.service';
import { Family } from '../../Models/Family';
import { Router } from '@angular/router';

@Component({
  selector: 'tabs',
  templateUrl: 'tabs.html',
  styleUrls: ['./tabs.css']
})
export class tabs implements OnInit {
  backgroundColor: string;
  constructor(public serv: ServService, private router: Router) {
    this.backgroundColor = this.serv.backgroundColor[0];
  }
  items: MenuItem[];
  family: Family;

  ngOnDestroy() {
    this.serv.visible = true;
    this.serv.visibleStatus = true
  }

  ngOnInit() {
    this.serv.visibleStatus = false
    // if (localStorage.getItem("family") !== null) {
    //     this.family = JSON.parse(localStorage.getItem("family"));
    //   }
    if (this.serv.user.iUserType != 42) {
      this.items = [
        { label: 'פרטי הורים', url: 'parentDetails' },
        { label: 'פרטי תלמידים', icon: 'fa-calendar', url: 'children-details' },
        { label: 'חיובים אחרונים', icon: 'fa-book', url: 'last-debits' },
        { label: 'פירוט תשלומים', icon: 'fa-book', url: 'family-credit' },
        { label: 'אמצעי תשלום', icon: 'fa-support', url: 'details-payment' },
        { label: 'אנשי קשר', icon: 'fa-support', url: 'family-contacts' }
      ];
    }
    else {
      this.items = [
        { label: 'פרטי הורים', url: 'parentDetails' },
        { label: 'פרטי תלמידים', icon: 'fa-calendar', url: 'children-details' },
        { label: 'פירוט תשלומים', icon: 'fa-book', url: 'family-credit' },
        { label: 'אמצעי תשלום', icon: 'fa-support', url: 'details-payment' },
        { label: 'אנשי קשר', icon: 'fa-support', url: 'family-contacts' }
      ];
    }
  }
  backToDebit() {

    this.router.navigateByUrl('/header/debit');

  }
  backToParentsAndChild() {

    this.router.navigateByUrl('/header/reports/parents-and-children');

  }
  backToCredit() {

    this.router.navigateByUrl('/header/credit');

  }
  backToParentsBalance() {
    this.router.navigateByUrl('/header/reports/parents-with-balance');
  }
  backToParents() {
    this.router.navigateByUrl('/header/reports/parents-list');
  }
}
