import { Component, OnInit } from "@angular/core";
import { Router, Data } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { ServService } from "../../app/serv.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { dailog } from "../../app/Additions/dailog/dailog";
import { HttpClient } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/toPromise";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/Rx";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  ip: any;
  res;
  publicIP;
  fileNameDialogRef: MatDialogRef<dailog>;
  constructor(
    public router: Router,
    public service: ServService,
    private mydialog: MatDialog,
    private http: HttpClient
  ) { }
  form: FormGroup;
  ngOnInit() {
    this.form = new FormGroup({
      nvUserName: new FormControl(),
      nvPassword: new FormControl()
    });
  }
  //ניתוב לדף התפריט
  login({ value, valid }) {

    return this.http
      .get("https://ipinfo.io/?token=3e8bc8bc92fb90")
      .toPromise()
     .then(response => {
    this.ip = response;
/*      this.ip = "213.8.65.216";
     return this.service
       .Login(value.nvUserName, value.nvPassword, this.ip) */

  
    return this.service
    .Login(value.nvUserName, value.nvPassword, this.ip.ip)

       .then(res => {
        console.log(res);
        if (res.iUserId > 0) {
          if (res.bFileToTransmission == true) {
            this.mydialog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "יש לעדכן שידור קובץ"
            });
          }
          // console.log(value.nvUserName, value.nvPassword);
          this.router.navigate(["/header/families"]);
        } else if (res.iUserId == -1) {
          this.mydialog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "שם משתמש או סיסמא אינם נכונים"
          });
        } else if (res.iUserId == -2 || res.iUserId == -3) {
          this.mydialog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "משתמש חסום,אנא פנה לשרות הלקוחות"
          });
        } else if (res.iUserId == -4) {
          this.mydialog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data:
              "כתובת IP אינה מתאימה לכתובת IP שהוגדרה ,אנא פנה לשרות הלקוחות"
          });
        } else if (res.iUserId == -5) {
          this.mydialog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "רישיון פג תוקף, ,אנא פנה לשרות הלקוחות"
          });
        }
      })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
     });
  }
}
