import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Form, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ServService } from '../../../app/serv.service';
import { LOCALE_TEXT } from '../../../app/Additions/constants';
import { Family } from '../../../Models/Family';



@Component({
  selector: 'app-parents-with-balance',
  templateUrl: './parents-with-balance.component.html',
  styleUrls: ['./parents-with-balance.component.css']
})
export class ParentsWithBalanceComponent implements OnInit {
  backgroundColor: string;
  private column;
  private rowData;
  public gridOptions;
  public gridColumnApi: any;
  private gridApi: any;
  private columnTypes;
  private visible:boolean;
  private families:Array<Family>
  numRows=0;
  sumTotal=0;
  sumBalance=0;
  currentiFamilyIdTemp;
  ngOnDestroy()
  {
    this.serv.currentiFamilyId = this.currentiFamilyIdTemp

  }

  constructor( public serv:ServService,private router: Router ) {
    this.backgroundColor=this.serv.backgroundColor[3];
   }


  ngOnInit() {
    this.serv.bfromParentsBalance = false
    this.serv.visible = true;

    // this.getFamilys();
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      onGridReady: this.onGridReady,
      defaultColDef: {
        resizable: true
    },
      enableRtl: true,
     // DomLayout : "forPrint"
    },

      this.column = [
        {
          headerName: "פתיחה",
          field: "iFamilyId",
          width: 120,
          suppressFilter: true,
          cellRenderer: function (params) {
            var eDiv = document.createElement("div");
            eDiv.innerHTML = '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
            var eButton = eDiv.querySelectorAll('#edit')[0];
            eButton.addEventListener('click', function () {
              params.context.componentParent.editFamily(params.data);
            });

            return eDiv;
          }
        },
        {
          headerName: "שורה", field: "indexRow", cellRenderer: function (params) {
            let z = '<p>' + (params.node.rowIndex + 1) + '</p>';
            return z;
          },
          width: 100,
          filter: "agNumberColumnFilter"
        },
        {
          headerName: "משפחה",
          field: "nvFamilyName",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "שם האב",
          field: "nvFirstNameF",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "נייד אב",
          field: "nvMobileF",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "שם האם",
          field: "nvFirstNameM",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "נייד אם",
          field: "nvMobileM",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "טלפון",
          field: "nvPhone",
          width: 120,
          filter: "agTextColumnFilter",
          sortable: true
        },
        {
          headerName: "יתרה לתשלום",
          field: "nBalance",
          width: 120,
          filter: "agNumberColumnFilter",
          sortable: true
        },
        {
          headerName: 'ס"ה לתשלום החודש',
          field: "nSumTotal",
          width: 120,
          filter: "agNumberColumnFilter",
          sortable: true
        }]
        this.getReport(JSON.parse(localStorage.getItem("familyStatuses")));
  };
  filterChanged(params)
  {
    let t=this.gridOptions.api.getFilterModel();

    this.gridOptions.api.forEachNode(function (node: any) {
     node.setDataValue("indexRow", node.rowIndex + 1);
    })
    let filteredRows=this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows=0;
    this.sumTotal=0;
    this.sumBalance=0;
    filteredRows.forEach(t=>{
      this.sumTotal+=t.data.nSumTotal;
      this.sumBalance+=t.data.nBalance;
      this.numRows++;
    });
  }
  sortChanged(){
   this.gridOptions.api.forEachNode(function (node: any) {
     node.setDataValue("indexRow", node.rowIndex + 1);
   });
  }
  editFamily(Family) {
    this.serv.currentiFamilyId = Family.iFamilyId
    this.serv.currentFamily = Family;
    this.router.navigateByUrl('/header/families/tabs/parentDetails');
    this.serv.bfromParentsBalance = true
    this.serv.visible=false
  }
 onBtExport() {
    // var params = {
    //     skipHeader: false,
    //     allColumns: false,
    //     fileName: 'פרטי תלמידים',
    //     sheetName: 'xls'
    // };

  //  this.gridOptions.api.exportDataAsCsv(params);
  var params = {
    skipHeader: false,
    allColumns: false,
    fileName: 'פרטי תלמידים'
};
this.gridApi.exportDataAsExcel(params);
  }

  getReport(lStatus) {
    this.serv.GetParentsReport(lStatus).then(res => {
      if (res) {
        this.rowData = res;
        this.numRows=0;
        this.sumTotal=0;
        this.sumBalance=0;

        for (let i = 0; i < (res as any[]).length; i++) {
          this.sumTotal+=res[i].nSumTotal;
          this.sumBalance+=res[i].nBalance;
          this.numRows++;
        }
      }

    });
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    params.api.sizeColumnsToFit();
  }

}
