import { Component, OnInit } from "@angular/core";
import "ag-grid-enterprise";
import { GridApi } from "ag-grid/dist/lib/gridApi";
import { LOCALE_TEXT } from "../../app/Additions/constants";
import { ServService } from "../../app/serv.service";
import { ElementRef, ViewChild } from '@angular/core'
import * as moment from "moment";
import { CodeTable } from "../../Models/CodeTable";
import { Router } from "@angular/router";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MobilityToAssociationsComponent } from "../mobility-to-associations/mobility-to-associations";
import { ChecksManagementComponent } from "../checksManagement/checks-management.component";
import { AppProxy } from "../../app/app.proxy";
import { isArray } from "util";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { dailog } from "../../app/Additions/dailog/dailog";


@Component({
  selector: "app-credit",
  templateUrl: "./credit.component.html",
  styleUrls: ["./credit.component.css"]
})
export class CrebitComponent implements OnInit {
  bIsEnableCredit: any;
  id: number;
  lCredits: number[];
  iCreditMethodType: number;
  general: Boolean = true;
  checks: Boolean = false;
  credit: Boolean = false;
  showSaveButton: boolean = false;
  collection: Boolean = false;
  lDebitAddition: CodeTable[];
  detailsArr: Array<any>;
  detailsArr2: Array<any>;
  bIsFirstTime: boolean = true;
  debitday: CodeTable;
  iCreditDay: number;
  montes: string[];
  monthsReturn: string[];
  dCreditMonth: Date = null;
  backgroundColor: string;
  visible: boolean = false;
  public columnDefs;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;
  dialogRef: MatDialogRef<MobilityToAssociationsComponent>;
  dialogRef2: MatDialogRef<ChecksManagementComponent>;
  firstAfterSave: boolean = false;
  numRows = 0;
  sumTotal = 0;
  isReturn: boolean;
  isVisible: boolean = false;
  formPopup: FormGroup;
  dFromDate: Date;
  dToDate: Date;
  isVisibleWarningPopup: boolean = false;

  public static creditFilterState = null;
  public static creditSortState = null;
  public static creditGroupState = [];
  public static creditColState = null;

  @ViewChild('buttonSave', { static: false }) inputEl: ElementRef;

  constructor(
    public service: ServService,
    private router: Router,
    private mydialog: MatDialog,
    private MobilityToAssociationsComponent: MatDialog,
 //   private mydialog: MatDialog,
    private ChecksManagementComponent: MatDialog
  ) {
    this.backgroundColor = this.service.backgroundColor[2];
    this.detailsArr = new Array<any>();
    this.detailsArr2 = new Array<any>();
    this.lCredits = new Array<number>();
    this.lDebitAddition = new Array<CodeTable>();
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            let dateParts = cellValue.split("/");
            let cellDate = new Date(
              Number(dateParts[2]) +
              "/" +
              // (Number(dateParts[1]) - 1) +
              Number(dateParts[1]) +
              "/" +
              Number(dateParts[0])
            );
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      }
    };
  }

  ngOnInit() {
    this.showSaveButton = false;
    this.debitday = this.service.lDebitDay;
    this.GetMonthesCodeTable();
    if (this.service.bfromCredit == true) {
      this.dCreditMonth = this.service.dCreditMonth;
      this.iCreditDay = this.service.iCreditDay;
      this.onSelectMonthDay(0);
    }
    this.service.bfromCredit = false;
    this.gridOptions = {
      onGridReady: this.onGridReady,
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      suppressRowClickSelection: true,
      defaultColDef: {
        resizable: true
    },
      rowClassRules: {
        existQuestion: function (params) {
          return params.data.bError == true;
        }

      }
      // DomLayout : "forPrint"
    };
    this.setColumns();
    this.formPopup = new FormGroup({
      dFromDate: new FormControl("", Validators.required),
      dToDate: new FormControl("", Validators.required),
    });
  }
  clearFiltering() {
    this.service.FilterCreditsArray.forEach(element => {
      this.gridOptions.api.setFilterModel("");
    });
    this.service.SortCreditsArray.forEach(element => {
      this.gridOptions.api.setSortModel("");
    });
  }

  filterChanged(params) {
    console.log("filterChanged", this.gridOptions.api.getFilterModel(), params);
    let t = this.gridOptions.api.getFilterModel();
    if (Object.values(t).length == 0 && this.firstAfterSave) {
      this.firstAfterSave = false;
      this.gridApi.setFilterModel(this.service.FilterCreditsArray[0]);
    } else {
      this.service.FilterCreditsArray = [];
      this.service.FilterCreditsArray.push(t);
    }
    let filteredRows = this.gridOptions.api.getModel().rootNode.childrenAfterFilter;
    this.numRows = 0;
    this.sumTotal = 0;
    filteredRows.forEach(t => {
      this.sumTotal += t.data.nAmount;
      this.numRows++;
    })
  }
  sortChanged() {
    let t = this.gridOptions.api.getSortModel();
    this.service.SortCreditsArray.push(t);
  }
  setColumns() {
    this.columnDefs = [
      {
        headerName: "פתיחה",
        field: "iFamilyId",
        width: 120,
        suppressFilter: true,
        cellRenderer: function (params) {
          var eDiv = document.createElement("div");
          eDiv.innerHTML =
            '<button mat-icon-button type="submit" class="btn-icon-grid" id="edit"><i class="icon-locked"></i></button>';
          var eButton = eDiv.querySelectorAll("#edit")[0];
          eButton.addEventListener("click", function () {
            params.context.componentParent.editFamily(params.data);
          });

          return eDiv;
        },
        pinned:'right'
      },
      {
        headerName: "משפחה",
        field: "nvFamilyName",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "א. תשלום",
        field: "nvPaymentMethodType",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס' ח-ן / שיק/ 4 ספרות",
        field: "nvPaymentMethodDetails",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום",
        field: "nAmount",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עמותה",
        field: "nvAssociationName",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "תאריך גביה",
        field: "dTransactDate2",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "עבור חודש",
        field: "dCreditDate2",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "תאריך צ'ק",
        field: "dCheckDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "סטטוס",
        field: "nvStatusType",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "מס' אישור",
        field: "nvConfirmationNum",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות",
        field: "nvComment",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: " הערות למשפחה",
        field: "nvCommentsFamily",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עבור קבלה",
        field: "nvToReceipte",
        width: 150,
        editable: true,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
    //  this.GetChildrenReport();
    if (this.gridOptions.api)
      this.gridOptions.api.setFilterModel(this.service.FilterCreditsArray[0]);
    this.firstAfterSave = true;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.service != undefined) {
      if (this.service.FilterCreditsArray.length > 0)
        this.service.FilterCreditsArray.forEach(element => {
          this.gridOptions.api.setFilterModel(element);
        });
      this.service.SortCreditsArray.forEach(element => {
        this.gridOptions.api.setSortModel(element);
      });
    }
    params.api.sizeColumnsToFit();

    if (this.service != undefined) {
      if (CrebitComponent.creditColState)
        this.gridOptions.columnApi.setColumnState(CrebitComponent.creditColState);
      if (CrebitComponent.creditGroupState[0])
        this.gridOptions.columnApi.setColumnGroupState(CrebitComponent.creditGroupState);
      if (CrebitComponent.creditSortState)
        this.gridOptions.api.setSortModel(CrebitComponent.creditSortState);
      if (CrebitComponent.creditFilterState)
        this.gridOptions.api.setFilterModel(CrebitComponent.creditFilterState);
    }
  }
  GetMonthesCodeTable() {
    this.service
      .GetMonthesCodeTable(this.service.user.iInstitutionId)
      .then(res => {
        if (res) {
          this.montes = res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  onSelectMonthDay(iStatusType) {
    this.numRows = 0;
    this.sumTotal = 0;
    var backUpdate = false
    var checkManeg = false
    if (this.isReturn == true)
      this.checksManagement(33)
    else {
      let lStatusType = [];
      if (this.dCreditMonth && this.iCreditDay) {
        if (this.credit) {
          this.iCreditMethodType = 13;
          iStatusType = 32;
        } else if (this.checks) {
          if (this.id == 1)
            iStatusType = 32;
          else
            iStatusType = 33;
          backUpdate = iStatusType == 33 ? true : false;
          checkManeg = iStatusType == 32 ? true : false;
          this.iCreditMethodType = 38;
        } else if (this.collection) {
          this.iCreditMethodType = 14;
          iStatusType = 32;
          backUpdate = false;
        } else if (this.general == true) this.iCreditMethodType = 0;
        if (iStatusType > 0) {
          lStatusType.push(iStatusType);
          if (this.credit || this.collection){
              lStatusType.push(62);
          }
          if (this.credit){
            lStatusType.push(34);
          }
        }

        this.service
          .GetCredit(
            this.dCreditMonth,
            this.iCreditDay,
            lStatusType,
            checkManeg,
            this.iCreditMethodType,
            false,
            backUpdate
          )
          .then(res => {
            if (res) {
              res.lCredit.forEach(c => {
                if (c.dTransactDate != null) {
                  c.dTransactDate2 = moment(c.dTransactDate).format("DD/MM/YYYY");
                }
                if (c.dCreditDate != null) {
                  c.dCreditDate2 = moment(c.dCreditDate).format("DD/MM/YYYY");
                }
                if (c.dCheckDate != null) {
                  c.dCheckDate = moment(c.dCheckDate).format("DD/MM/YYYY");
                }
              });
              this.rowData = res.lCredit;
              this.rowData.forEach(t => {
                this.sumTotal += t.nAmount;

                this.numRows++;
              })
              if (this.gridOptions.api)
                this.gridOptions.api.setFilterModel(CrebitComponent.creditFilterState);


              this.bIsFirstTime = res.bIsFirstTime;
              this.bIsEnableCredit = res.bIsEnableCredit;
              //i slashed this because onSelect other month it returns me to the general
              // this.setColumns();
            } else {
              alert("אירעה שגיאה בלתי צפויה");
            }
          });
      }
      // if (this.isReturn == true)
      //    this.checksManagement(33)
    }
  }
  GetCredit(iStatusType) {
    this.numRows = 0;
    this.sumTotal = 0;
    this.lCredits = new Array<number>();
    if (this.credit) this.iCreditMethodType = 13;
    else if (this.checks) this.iCreditMethodType = 38;
    else if (this.collection) this.iCreditMethodType = 14;
    else if (this.general == true) this.iCreditMethodType = 0;
    let lStatusType = [];
    if (isArray(iStatusType))
      lStatusType = iStatusType;
    else
      lStatusType.push(iStatusType);
    if (this.dCreditMonth && this.iCreditDay) {
      // if (this.credit)
      // lStatusType.push(62);
      this.service
        .GetCredit(
          this.dCreditMonth,
          this.iCreditDay,
          lStatusType,
          false,
          this.iCreditMethodType,
          false,
          false
        )
        .then(res => {
          if (res) {

            res.lCredit.forEach(c => {
              if (c.dTransactDate != null) {
                c.dTransactDate2 = moment(c.dTransactDate).format("DD/MM/YYYY");
              }
              if (c.dCreditDate != null) {
                c.dCreditDate2 = moment(c.dCreditDate).format("DD/MM/YYYY");
              }
              if (c.dCheckDate != null) {
                c.dCheckDate = moment(c.dCheckDate).format("DD/MM/YYYY");
              }
            });
            this.rowData = res.lCredit;
            this.rowData.forEach(t => {
              this.sumTotal += t.nAmount;
              this.numRows++;
            })


            // איפה התאריכים המסודרים?
            this.bIsFirstTime = res.bIsFirstTime;
            console.log(this.rowData);
            this.setColumns();
          } else {
            alert("אירעה שגיאה בלתי צפויה");
          }
        });
    }
  }
  UpdFileTransmission(iCreditDay: number, dCreditMonth: Date) {
    this.service
      .UpdFileTransmission(iCreditDay, dCreditMonth)
      .then(res => {
        if (res) {
          this.onSelectMonthDay(33);
        } else {
          alert("אירעה שגיאה בלתי צפויה");
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  editFamily(Family) {
    this.service.currentiFamilyId = Family.iFamilyId;
    this.service.currentFamily = Family;

    CrebitComponent.creditColState = this.gridOptions.columnApi.getColumnState();
    CrebitComponent.creditGroupState = this.gridOptions.columnApi.getColumnGroupState();
    CrebitComponent.creditSortState = this.gridOptions.api.getSortModel();
    CrebitComponent.creditFilterState = this.gridOptions.api.getFilterModel();

    this.router.navigateByUrl("/header/families/tabs/parentDetails");

    this.service.visible = false;
    this.service.bfromCredit = true;
    this.service.dCreditMonth = this.dCreditMonth;
    this.service.iCreditDay = this.iCreditDay;
  }
  mobilityToAssociations() {
    this.detailsArr = new Array<any>();
    this.detailsArr.push(this.dCreditMonth, this.iCreditDay);
    this.dialogRef = this.MobilityToAssociationsComponent.open(
      MobilityToAssociationsComponent,
      {
        hasBackdrop: true,
        height: "800px",
        width: "1800px",
        data: this.detailsArr
      }
    );
  }
  ResetMobilityToAssociations() {
    this.service
      .ResetMobilityToAssociations()
      .then(res => {
        if (res) {
        } else {
          alert("אירעה שגיאה בלתי צפויה");
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  checksManagement(iStatusType) {
    this.showSaveButton = true;
    if (iStatusType == 33)
      this.isReturn = true;
    this.GetCheckManagement(iStatusType);
    // this.onSelectMonthDay(0)
    this.columnDefs = [
      {
        headerName: "בחירה",
        // field: "iCreditId",
        field: "checkboxM",
        width: 100,
        suppressFilter: true,
        checkboxSelection: true,
        pinned:'right'
        // cellRenderer: function (params) {
        //   var eDiv = document.createElement("div");
        //   eDiv.innerHTML = '<input type="checkbox" id="edit" >';
        //   var eButton = eDiv.querySelectorAll("#edit")[0];
        //   eButton.addEventListener("change", function (event) {
        //     console.log((event.target as any).checked);
        //     params.context.componentParent.addCheck(
        //       params.data,
        //       (event.target as any).checked
        //     );
        //   });

        //   return eDiv;
        // }
      },
      {
        headerName: "משפחה",
        field: "nvFamilyName",
        width: 250,
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "תאריך גביה",
        field: "dTransactDate2",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "עבור חודש",
        field: "dCreditDate2",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "תאריך צ'ק",
        field: "dCheckDate",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        comparator: this.dateComparator,
        sortable: true
      },
      {
        headerName: "פרטי תשלום",
        field: "nvPaymentMethodDetails",
        width: 250,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום",
        field: "nAmount",
        width: 120,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סטטוס",
        field: "nvStatusType",
        width: 150,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "הערה",
        field: "nvComment",
        width: 300,
        editable: true,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עבור קבלה",
        field: "nvToReceipte",
        width: 300,
        editable: true,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
  }
  onBtStartEditing(i) {
    this.gridApi.setFocusedCell(i, "nvComment");
    this.gridApi.startEditingCell({
      rowIndex: i,
      colKey: "nvComment"
    });
  }
  checksList() {
    this.showSaveButton = true;
    this.GetCheckList();
    this.columnDefs = [
      {
        headerName: "בחר",
        field: "checkbox",
        checkboxSelection: true,
        suppressFilter: true,
        menuTabs: [],
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        pinned:'right'
      },
      {
        headerName: "משפחה",
        field: "nvFamilyName",
        width: 300,
        filter: "agTextColumnFilter",
        sortable: true
      },

      {
        headerName: "מס שיק",
        field: "iCheckNum",
        width: 150,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "סכום",
        field: "nAmount",
        editable: true,
        width: 180,
        sortable: true
      },
      {
        headerName: "יתרה",
        field: "nBalance",
        width: 190,
        filter: "agDateColumnFilter",
        type: "dateColumn",
        sortable: true
      },
      {
        headerName: "הערה",
        field: "nvComment",
        editable: true,
        width: 300,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "הערות",
        field: "nvComments",
        editable: true,
        width: 300,
        filter: "agTextColumnFilter",
        sortable: true
      },
      {
        headerName: "עבור קבלה",
        field: "nvToReceipte",
        width: 150,
        editable: true,
        filter: "agTextColumnFilter",
        sortable: true
      }
    ];
  }
  GetCheckList() {
    this.numRows = 0;
    this.sumTotal = 0;
    this.id = 0;
    this.service
      .GetCheckList(this.dCreditMonth)
      .then(res => {
        if (res) {
          this.rowData = res;
          this.rowData.forEach(t => {
            this.sumTotal += t.nAmount;
            this.numRows++;
          })
          for (let i = 0; i < res.length; i++) {
            res[i].isChecked = false;
          }
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetCheckManagement(iStatusType) {
    this.numRows = 0;
    this.sumTotal = 0;
    if (iStatusType == 32) this.id = 1;
    else this.id = 2;
    let lStatusType = [];
    lStatusType.push(iStatusType);
    var backUpdate = iStatusType == 33 ? true : false;
    var checkManeg = iStatusType == 32 ? true : false;

    this.service
      .GetCredit(
        this.dCreditMonth,
        this.iCreditDay,
        lStatusType,
        checkManeg,
        this.iCreditMethodType,
        false,
        backUpdate
      )
      .then(res => {
        if (res) {
          res.lCredit.forEach(c => {
            if (c.dTransactDate != null) {
              c.dTransactDate2 = moment(c.dTransactDate).format("DD/MM/YYYY");
            }
            if (c.dCreditDate != null) {
              c.dCreditDate2 = moment(c.dCreditDate).format("DD/MM/YYYY");
            }
            if (c.dCheckDate != null) {
              c.dCheckDate = moment(c.dCheckDate).format("DD/MM/YYYY");
            }
          });
          this.rowData = res.lCredit;
          this.rowData.forEach(t => {
            this.sumTotal += t.nAmount;
            this.numRows++;
          })
          this.gridApi.setRowData(this.rowData)
        }
      });
  }
  addCheck(params, isChecked) {
    // if (isChecked == true) this.lCredits.push(params.iCreditId);
    // else {
    //   this.lCredits.forEach((element, index) => {
    //     if (params.iCreditId == element)
    //       this.lCredits = this.lCredits.slice(index, 1);
    //   });
    // }
    // console.log(this.lCredits);
    if (isChecked == true) this.lCredits.push(params.iCheckId);
    else {
      this.lCredits.forEach((element, index) => {
        if (params.iCheckId == element)
          this.lCredits.splice(index, 1);
        // this.lCredits = this.lCredits.slice(index, 1);
      });
    }
    console.log(this.lCredits);
  }

  lDebitAddition2 = [];

  onSelectionChanged(params) {
    this.lDebitAddition2 = this.gridApi.getSelectedRows();
    this.lDebitAddition2.forEach(element => {
      element.nAmount = element.nBalance;
    });
    params.api.selectionController.lastSelectedNode.data.isChecked = !params.api
      .selectionController.lastSelectedNode.data.isChecked;
    if (this.id == 0) {
      params.api.selectionController.lastSelectedNode.data.nAmount =
        params.api.selectionController.lastSelectedNode.data.nBalance;
      params.api.selectionController.lastSelectedNode.setDataValue(
        "nAmount",
        params.api.selectionController.lastSelectedNode.data.nBalance
      );
    }

  }
  dateComparator(date1, date2) {
    if (
      (date1 === null && date2 === null) ||
      (typeof date1 == "undefined" && typeof date2 == "undefined")
    ) {
      return 0;
    }
    if (date1 === null || typeof date1 == "undefined") {
      return -1;
    }
    if (date2 === null || typeof date2 == "undefined") {
      return 1;
    }
    let dateParts1 = date1.split("/");
    let cellDate1 = new Date(
      Number(dateParts1[1]) +
      "/" +
      Number(dateParts1[0]) +
      "/" +
      Number(dateParts1[2])
    );
    let dateParts2 = date2.split("/");
    let cellDate2 = new Date(
      Number(dateParts2[1]) +
      "/" +
      Number(dateParts2[0]) +
      "/" +
      Number(dateParts2[2])
    );
    return cellDate1.getTime() - cellDate2.getTime();
  }
  rowDataChanged(event) {
    console.log("rowDataChanged");
    this.gridOptions.api.setFilterModel(this.service.FilterCreditsArray[0]);
    this.firstAfterSave = true;

    if (this.gridOptions.api)
      this.gridOptions.api.setFilterModel(CrebitComponent.creditFilterState);

    if (this.id == 0)
      this.initCheckbox();
  }

  initCheckbox() {
    if (this.gridApi) {
      const component = this;
      let nodes = this.gridApi.getRenderedNodes();
      nodes.forEach(function (node: any) {
        node.setSelected(node.data.isChecked);
      });
    }
  }
  saveCredits() {
    window.location.hash = '#buttonSave';
    //this.inputEl.nativeElement.focus();
    //this.saveCredit.setFocusedCell
    //@ViewChild(this.columnDefs[this.columnDefs.length-1]) textInput;
    //this.textInput.nativeElement.blur();
    //document.activeElement.blur();

    this.gridApi.stopEditing();
    let credits = [];
    this.lCredits = []
    this.lCredits = this.gridApi.getSelectedRows().filter(f => f.iCheckId != null || f.iCreditId != null).map(p => p.iCheckId ? p.iCheckId : p.iCreditId);
    this.lCredits.forEach(c => credits.push
      ({
        id: c, text: this.rowData.find
          (r => r.iCheckId == c || r.iCreditId == c).nvComment
      }))
    // this.lCredits.forEach(c=>credits.push({id:c,text:this.rowData.find(r=>r.iCreditId==c).text}))
    if (this.id == 1) {
      this.service
        .UpdCheckStatus(credits, 33)
        .then(res => {
          this.GetCredit([32, 34]);
        })
        .catch(err => {
          console.log("err-----" + err);
          return false;
        });
    }
    else if (this.id == 2) {
      if (this.iCreditMethodType == 38) var status = 35;
      else if (this.iCreditMethodType == 14) status = 34;
      if (this.checks) {
        this.service
          .UpdCheckStatus(credits, status)
          .then(res => {
            this.GetCredit([32, 34]);
          })
          .catch(err => {
            console.log("err-----" + err);
            return false;
          });
      } else {
        this.service
          .UpdCreditStatus(credits, status)
          .then(res => {
            this.GetCredit([32, 34]);
          })
          .catch(err => {
            console.log("err-----" + err);
            return false;
          });
      }

    }
    else if (this.id == 0) {
      let lDebitAddition = [];
      this.rowData.forEach(element => {
        if (element.isChecked == true) {
          lDebitAddition.push({
            iCheckId: element.iCheckId,
            nAmount: element.nAmount,
            nvComment: element.nvComment
          });
        }
      });
      this.service
        // .UpdCheckAmount(lDebitAddition)

        .UpdCheckAmount(this.lDebitAddition2)
        .then(res => {
          this.GetCredit([32, 32]);
        })
        .catch(err => {
          console.log("err-----" + err);
          return false;
        });
    }
  }

  ChargeAgain() {
    this.service
      .ChargeAgain(this.dCreditMonth, this.iCreditDay)
      .then(res => {
        this.ChargeCC();
      });
  }

  ChargeCC() {
    if (this.service.default1.iCCCompanyType == 60)
      this.service
        .PeleCardCharge(this.dCreditMonth, this.iCreditDay, 13)
        .then(res => {

          this.GetCredit([32, 34, 62]);
        });
    else {
      this.service
        .ZCreditCharge(this.dCreditMonth, this.iCreditDay)
        .then(res => {
          this.GetCredit([32, 34, 62]);
        });
    }
  }
  CheckFileTransmission() {
    this.service
      .CheckFileTransmission()
      .then(res => {
        if (res>0) {
          this.isVisibleWarningPopup = true;
        }
        else {
          this.createFileMasav()
        }
      }
      );
  }
  createFileMasav() {
    this.service
      .createFileMasav(this.dCreditMonth, this.iCreditDay, 14)
      .then(res => {
        res.forEach(element => {
          var link = document.createElement("a");
          // link.download = "מסב" + ".msv";
          link.download = "" + element;
          //link.href = "http://new.active-smart.co/Ws/Files/"  + element;
          link.href = AppProxy.baseUrl + "Files/" + element;
         // this.mydialog.open(dailog, {
           // hasBackdrop: true,
           // height: "200px",
           // width: "300px",
           // data: link.href
          //});
          link.click();
        });
      });
  }
  InsAutomaticCredit() {
    this.service
      .InsAutomaticCredit(this.dCreditMonth, this.iCreditDay, 14)
      .then(res => {
        this.onSelectMonthDay(0);
      });
  }
  cellEditingStopped(params) {
    this.rowData.forEach(element => {
      if (element.iCreditId == params.data.iCreditId) {
        element = params.data;
        // if (params.api.selectionController.lastSelectedNode)
        params.api.selectionController.lastSelectedNode.setDataValue(
          "nAmount",
          params.data.nAmount
        );
      }
    });
  }

  CreateReceipts() {
    if (this.dFromDate && this.dToDate)
      this.service.CreateReceipts(this.service.user.iUserId, this.service.user.nvGuide, this.service.user.iInstitutionId, this.dFromDate, this.dToDate);
  }

  PrintReceipts() {
    if (this.dFromDate && this.dToDate)
      this.service.PrintReceipts(this.service.user.iUserId, this.service.user.nvGuide, this.service.user.iInstitutionId, this.dFromDate, this.dToDate);
  }

  tempDCreditMonth
  GetMonthesCodeTableForUpdateReturn() {
    this.tempDCreditMonth = this.dCreditMonth;
    this.service.GetMonthesCodeTableForUpdateReturn(this.service.user.iUserId, this.service.user.nvGuide, this.service.user.iInstitutionId).then(
      res => {
        if (res) {
          this.monthsReturn = res;
          //this.montes = res;
        }
      }
    );
  }

  setMonth() {
    if (this.tempDCreditMonth) {
      this.dCreditMonth = this.tempDCreditMonth; 
      this.monthsReturn = [];
    }
  }

}
