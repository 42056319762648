import { UpdateChildDetails } from "./UpdateChildrenDetails";


export class ImportToExcel {
nvFamilyName:string=null
nvPhone:string=null
iDebitDay:number=null
nvCityName:string=null
nvAddress:string=null
nvNumHouse:string=null
nvNeighborhood:string=null
nvZipCode:string=null
nvLastNameF:string=null
nvFirstNameF:string=null
bIsParentHeadF:string=null
nvIdentityF:string=null
iIdentityTypeF:number=null
nvMobileF:string=null
nvWorkPlaceF:string=null
nvWorkAddressF:string=null
bIsParentHeadM:number=null
nvLastNameM:string=null
nvFirstNameM:string=null
nvIdentityM:string=null
iIdentityTypeM:number=null
nvMobileM:string=null
nvWorkPlaceM:string=null
nvWorkAddressM:string=null
iBankType:number=null
iBranchNumber:number=null
iaccountNumber:number=null
nvAccountName:string=null
nMaxAmount:number=null
iRowNumber:number=null
nvIdentityC:string=null
iIdentityTypeC:number=null
nvFirstNameC:string=null
dBirthDate:Date=null
nvJewBirthDate:string=null
dRegisteredDate:Date=null
dBeginDate:Date=null
dEndDate:Date=null
nvBranchName:string=null
nvLevel:string=null
nTuitionAmount:string=null
nDiscountPerc:string=null
nDiscountAmount:string=null
bDiscSet:boolean=null
nTransAmount:string=null
nMealsAmount:string=null
nvTranspGoType:number=null
nvTranspReturnType:number=null
nvTranspReturnFriType:number=null
nvAccountIdentity :string=null
nvCardLastFourDigits:string=null
nvTokenNumber:string =null
nvTuitionRateName:string=null
nvTransRateName:string=null
nvEtraRateName:string=null
nvKupatCholim:string=null
dBirthDateF:string=null
dBirthDateM:string=null
}
