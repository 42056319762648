export class Family{
    iFamilyId:number
    nvFamilyName :string
    nvFatherName :string
    nvMotherName :string
    nvPhoneF :string
    nvPhoneM :string
    nvPhone :string
    nBalance :number
    dDebitDate:Date
}