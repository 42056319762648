import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LOCALE_TEXT } from '../../app/Additions/constants';
import { ServService } from '../../app/serv.service';
import * as moment from "moment"

@Component({
  selector: 'app-debit-details',
  templateUrl: './debit-details.component.html',
  styleUrls: ['./debit-details.component.css']
})
export class DebitDetailsComponent implements OnInit {

  backgroundColor: string;
  public columnDefs;
  public rowData;
  public gridOptions;
  public gridColumnApi: any;
  public gridApi: any;
  public columnTypes;

  constructor(public service: ServService, public dialogRef: MatDialogRef<DebitDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.backgroundColor = this.service.backgroundColor[1];
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive:true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            if (cellValue != null) {
              let dateParts = cellValue.split("/");
              let cellDate = new Date(Number(dateParts[1]) + "/" + Number(dateParts[0]) + "/" + Number(dateParts[2]));
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        }
      }
    };
  }

  ngOnInit() {

    this.getDebitDetails();
    this.gridOptions = {
      localeText: LOCALE_TEXT,
      context: { componentParent: this },
      enableRtl: true,
      defaultColDef: {
        resizable: true
    },
      onGridReady: this.onGridReady,
    },
      this.columnDefs = [

        // {
        //   headerName: "תאריך עדכון",
        //   field: "dtCreateDate",
        //   width: 120,
        //   filter: "agDateColumnFilter"
        // },
        {
          headerName: "שם תלמיד",
          field: "nvFirstName",
          width: 100,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "כתה",
          field: "nvLevel",
          width: 100,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סכום",
          field: "nAmount",
          width: 100,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "הנחה",
          field: "nDiscountAmount",
          width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: " לאחר הנחה",
          field: "nAmountAfterDiscount",
          width: 150,
          filter: "agTextColumnFilter"
        },
        // {
        //   headerName: "תאריך ביצוע חיוב",
        //   field: "dDebitDate",
        //   width: 150,
        //   filter: "agDateColumnFilter"
        // },
        {
          headerName: "סוג תעריף",
          field: "nvRateType",
          width: 120,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "סוג חיוב",
          field: "nvDebitType",
          width: 100,
          filter: "agTextColumnFilter"
        },

        {
          headerName: "הערות",
          field: "nvComment",
          width: 100,
          filter: "agTextColumnFilter"
        }

      ]

  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }
  getDebitDetails() {
    console.log(this.service.currentiFamilyId)
    this.service.GetDebitDetails(this.data[0], this.data[1]).then(res => {

      if (res) {
        this.rowData = res;
        console.log(this.rowData);
        for (let i = 0; i < res.length; i++) {
          if (res[i].dtCreateDate != null) {
            res[i].dtCreateDate = moment(res[i].dtCreateDate).format("DD/MM/YYYY");
          } else if (res[i].dDebitDate == null) {
            res[i].dtCreateDate = ""
          }
          if (res[i].dDebitDate != null) {
            res[i].dDebitDate = moment(res[i].dDebitDate).format("DD/MM/YYYY");

          } else if (res[i].dDebitDate == null) {
            res[i].dDebitDate = ""

          }
        }
      }

    }).catch(err => { console.log("err-----" , err); return false; })
  }
  closeDialog() {
    this.dialogRef.close()
  }
}


